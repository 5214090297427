<template>
    <v-form ref="form" v-if="item">
        <v-card-title>{{ item.name }}
            <v-spacer></v-spacer>
            <v-alert dense type="warning" v-if="isSaveAlertVisible" class="mr-3 mb-0 py-1">Er zijn onopgeslagen wijzigingen</v-alert>
            <v-btn @click="saveItem" :loading="saving" v-if="isSaveAlertVisible" color="secondary" depressed rounded><v-icon left>mdi-content-save</v-icon>Opslaan</v-btn>
        </v-card-title>
        <template v-if="item && !['TOOL_FOLDER'].includes(item.class)">
            <v-card-text>
                <v-alert v-if="errors" color="error" text>
                <ul>
                    <li v-for="(error, index) in errors" :key="'error_'+index">{{ error }}</li>
                </ul>
                </v-alert>
            <v-row class="mb-4">
                <v-col>
                    <v-row>
                    <v-col cols="12" :xl="6">
                        <eod-text-field
                            v-model="item.name"
                            :rules="[val => $validator.required(val, 'Naam')]"
                            label="Naam*"
                            required
                            outlined
                            hide-details
                            dense
                        ></eod-text-field>
                    </v-col>
                    <v-col cols="12" :xl="6">
                        <eod-text-field
                            v-model="item.description"
                            label="Omschrijving"
                            outlined
                            hide-details
                            dense
                        ></eod-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" :xl="6">
                        <eod-object-autocomplete dense hide-details :rules="[val => $validator.required(val, 'Type')]" label="Type*" endpoint="articleTypes" v-model="item.articleType.id" required>
                            <template v-slot:append-item>
                                <v-divider></v-divider>
                                <v-list-item dense @click="isCreateTypeDialogVisible = true">
                                    <v-list-item-icon>
                                        <v-icon>mdi-plus</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Type toevoegen</v-list-item-title>
                                        <v-list-item-subtitle>Voeg een type toe wanneer u deze
                                            niet in de lijst vindt.</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </eod-object-autocomplete>
                        <eod-dialog title="Type toevoegen" icon="mdi-plus" iconColor="primary" v-if="isCreateTypeDialogVisible" v-model="isCreateTypeDialogVisible" @okAction="createArticleType" okText="Toevoegen">
                            <v-form ref="articleTypeForm">
                                <v-alert type="error" v-if="typeFormErrors[0]" dense class="mt-2">
                                    <ul>
                                        <li v-for="(error, index) in typeFormErrors" :key="index">{{ error }}</li>
                                    </ul>
                                </v-alert>
                                <eod-article-type-detail class="mt-4" v-model="newArticleType"></eod-article-type-detail>
                            </v-form>
                        </eod-dialog>
                    </v-col>
                    <v-col cols="12" :xl="6">
                        <eod-objects-treeview-input :classes="['DEPOT', item.class+'_FOLDER', item.class]" :rules="[val => $validator.required(val, 'Bovenliggend item')]" required v-model="item.treeParentId" :exclude="item.id?[item.id]:null" label="Bovenliggend item*"></eod-objects-treeview-input>
                    </v-col>
                </v-row>
                <v-row v-if="item.articleType && item.articleType.id">
                    <v-col>                        
                        <eod-product-type-preview :value="item.articleType.id"></eod-product-type-preview>
                    </v-col>
                </v-row>
                </v-col>
                <v-col cols="12" :lg="'auto'">
                    <v-img width="200" max-height="280" :src="qr_code" aspect-ratio="1" contain />
                </v-col>
            </v-row>
    </v-card-text>
</template>
    <v-card-text v-else-if="['TOOL_FOLDER'].includes(item.class)">
        <v-text-field label="Naam" dense outlined v-model="item.name"></v-text-field>
    </v-card-text>
        <v-snackbar right top v-model="isSavedSnackbarVisible" rounded="pill" timeout="5000" color="success"><v-icon left>mdi-content-save</v-icon>Item succesvol bewaard!</v-snackbar>
</v-form>
</template>
<script>
import QRCode from 'qrcode'
import eodObjectAutocomplete from './eod-object-autocomplete.vue';
import eodObjectsTreeviewInput from './eod-objects-treeview-input.vue';
import eodProductTypePreview from './eod-product-type-preview.vue';
import eodArticleTypeDetail from './eod-article-type-detail.vue';
import Article from './../models/article.js';

export default{
    name: 'eod-article-detail',
    components:{
        eodObjectAutocomplete,
        eodObjectsTreeviewInput,
        eodProductTypePreview,
        eodArticleTypeDetail
    },
    props:{
        value: String|Object
    },
    data: () => ({
        item: null,
        loading: false,
        saving: false,
        isSaveAlertVisible: false,
        isSavedSnackbarVisible: false,
        errors: null,
        selectedtab: 'general',
        qr_code: null,
        isCreateTypeDialogVisible: false,
        newArticleType: {},
        typeFormErrors: [],
    }),
    created(){
        if(this.value){
            if(typeof this.value == 'string'){
                this.loading = true;
                
                this.$eod.getById('article', this.value, ['id', 'name', 'treeParentId', 'description', 'articleType{id}', 'class'])
                    .then(response => {
                        if(response && response.data.data && response.data.data.article){
                            this.item = new Article(response.data.data.article);
                        }else{
                            this.item = null;
                        }
                    }).finally(() => {
                        this.loading=false;
                    });
            }else{
                this.item = this.value
            }
        }
        

        // QR code
        QRCode.toDataURL(window.location.href).then(result => {
            this.qr_code = result;
        });
    },
    watch:{
        loading(val){
            this.$emit('loading', val);
        },
        item: {
            deep: true,
            handler() {
                if (!this.loading && !this.isSaveAlertVisible) {
                    this.isSaveAlertVisible = true;
                }
            }
        }
    },
    methods:{
        createArticleType(){
            if(this.$refs.articleTypeForm){
                const valid = this.$refs.articleTypeForm.validate();

                if(!valid){
                    this.typeFormErrors = [];
                    this.$refs.articleTypeForm.inputs.forEach(e => {
                        if (e.errorBucket && e.errorBucket.length) {
                            this.typeFormErrors.push(...e.errorBucket);
                        }
                    });

                    return false;
                }

                this.$eod.save('ArticleType', this.newArticleType).then((response) => {
                    if(response.data && response.data.data && response.data.data.createArticleType){
                        this.item.articleType = response.data.data.createArticleType;
                        this.isCreateTypeDialogVisible = false;
                        this.newArticleType = {};
                    }
                });
            }
        },
        getFormErrors() {
            const errors = [];
            this.$refs.form.inputs.forEach(e => {
                if (e.errorBucket && e.errorBucket.length) {
                    errors.push(...e.errorBucket);
                }
            });
            return errors;
        },
        saveItem(){
            this.errors = null;
            if(!this.$refs.form){
                return false;
            }

            const validation = this.$refs.form.validate();
            if(!validation){
                this.errors = this.getFormErrors();
                return false;
            }

            this.saving = true;

            const data = (new Article(this.item)).getSaveData();
            this.$eod.save('Article', data)
                .then(response => {
                    if(response && response.data.data){
                        if(response.data.data.createArticle){
                            this.$emit('saved', response.data.data.createArticle.id);
                        }else if (response.data.data.updateArticle){
                            this.$emit('saved', response.data.data.updateArticle.id);
                        }

                        this.isSavedSnackbarVisible = true;
                    }
                })
                .finally(() => {
                    this.isSaveAlertVisible = false;
                    this.saving = false;
                });
        },
    }
}
</script>