<template>
    <div class="h-100 w-100">
        <v-chart v-show="value.length > 0" @finished="finished" :ref="id" :initOptions="graphOptions" :autoresize="true"
            :manualUpdate="true" />
        <div v-if="!value">Gelieve de grafiek in te stellen</div>
        <div v-else-if="value.length == 0">Geen waardes gevonden</div>
    </div>
</template>
<style lang="scss">
.echarts {
    width: 100%;
    height: 100%;
}
</style>
<script>
import ECharts from './echarts/ECharts';
import Event from "../../models/event";

export default {
    name: 'eod-graph-pie',
    components: {
        'v-chart': ECharts,
    },
    props: {
        id: String,
        value: Object | Array,
        cell: Object,
        template: Object | Array,
        group: String,
        defaultSerie: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data: () => ({
        graphOptions: {},
        defaultOptions: {
            renderer: 'svg',
            tooltip: {
                confine:true,
                trigger: 'item',
                formatter: function (params) {
                    let label = params.marker + params.name + ': <strong>' + params.percent + '%</strong>';
                    return label;
                }
            },
            series: [],
        }
    }),
    methods: {
        finished() {
            this.$emit('finished', this.id, this.$refs[this.id]);
        },
        resize() {
            this.$refs[this.id].resize();
        },
        update() {

            this.graphOptions = {
                ...this.defaultOptions,
            };

            const serie =
            {
                name: this.template[0].name,
                type: 'pie',
                radius: '60%',
                data: [],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            };

            if (this.template[0].content) {
                for (let i = 0; i < this.value.length; i++) {
                    const value = this.value[i];
                    if (value) {
                        for (let j = 0; j < value.data.length; j++) {
                            const data = value.data[j];
                            const val = {
                                value: data[this.template[0].content.measurementTypes[0].object.id],
                                name: Event.getSeverity(data[this.template[0].content.group.key]).text,
                            };

                            if (this.template[0].content.group.key == 'severity') {
                                val.itemStyle = {
                                    color: Event.getSeverity(data[this.template[0].content.group.key]).color
                                };
                            }

                            serie.data.push(val);
                        }
                    }

                }
            }

            this.graphOptions.series = [serie];

            if (this.$refs[this.id]) {
                this.$refs[this.id].mergeOptions(this.graphOptions);
                this.$nextTick(() => {
                    this.$forceUpdate();
                });
            } else {
                console.log('graph not found');
            }

        }
    }
}
</script>