<template>
    <div id="eod-scheduler" class="eod-scheduler">
        <div id="eod-scheduler-wrapper" class="eod-scheduler__wrapper">
            <template v-if="rows && rows.length > 0">
                <table class="eod-scheduler__table">
                    <thead id="eod-scheduler__header" style="position:sticky;top:0;z-index:2;">
                        <tr>
                            <th class="position-sticky col-header">
                                <div class="eod-scheduler__row-title d-flex align-items-center py-0">
                                    <v-btn class="mr-2" icon small @click="toggleView('all')"><v-icon small
                                            v-html="showAll ? 'mdi mdi-eye' : 'mdi mdi-eye-off'"></v-icon></v-btn>
                                </div>
                            </th>
                            <template v-for="(header, date) in headers">
                                <th v-for="child in getSubHeaders(header)" :colspan="child.colspan"
                                    :key="date + '_' + child.date"
                                    :style="'min-width:' + (child.width) + 'px;max-width:' + (child.width) + 'px;'">
                                    {{ child.date }}
                                </th>
                            </template>
                        </tr>
                    </thead>
                    <tbody id="eod-scheduler__body">
                        <tr v-for="row in rows" :key="row.id">
                            <th class="position-sticky col-header">
                                <div class="eod-scheduler__row-title " :style="row.visible ? '' : 'opacity:0.4'">

                                    <div class="d-flex align-items-center ml-1">
                                        <v-avatar size="15" small :color="row.color" class="mr-2"></v-avatar>
                                        <div class="d-flex flex-column content" @click="routeClicked(row.id)">
                                            <span>{{ row.name }}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <v-btn @click="toggleView(row.id)" icon small><v-icon small
                                                v-html="row.visible ? 'mdi mdi-eye' : 'mdi mdi-eye-off'"></v-icon></v-btn>
                                        <v-btn :loading="lockLoading" @click="lockRoute(row.id)" icon small><v-icon
                                                small>mdi-lock</v-icon></v-btn>
                                        <v-btn :loading="unlockLoading" @click="unlockRoute(row.id)" icon small><v-icon
                                                small>mdi-lock-open</v-icon></v-btn>
                                    </div>
                                    <div class="d-flex">
                                        <template v-if="row.vehicle">
                                            <v-btn v-if="row.isUsingTrailer"
                                                :to="{ name: 'vehicle', params: { id: row.vehicle.id } }" target="_blank">
                                                <v-icon>mdi-truck-trailer</v-icon>
                                            </v-btn>
                                            <v-tooltip right>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" v-on="on" icon
                                                        :to="{ name: 'vehicle', params: { id: row.vehicle.id } }"
                                                        target="_blank">
                                                        <v-icon>mdi-truck</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>{{ row.vehicle.name }}</span>
                                            </v-tooltip>

                                        </template>
                                        <v-spacer></v-spacer>
                                        <template v-if="row.responsibleUsers">
                                            <router-link class="ml-2" v-for="user in row.responsibleUsers" :key="user.id"
                                                :to="{ name: 'organizationUser', params: { id: user.id } }" target="_blank">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-avatar size="24" color="primary" v-bind="attrs" v-on="on">
                                                            <span class="white--text body-1">
                                                                {{ user.getFullName().charAt(0) }}
                                                            </span>
                                                        </v-avatar>
                                                    </template>
                                                    <span>{{ user.getFullName() }}</span>
                                                </v-tooltip>

                                            </router-link>

                                        </template>

                                    </div>
                                    <div class="d-flex" style="height:20px;width:100%;">
                                        <v-tooltip top v-for="duration in durations" :key="duration.id">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="eod-duration__item" v-bind="attrs" v-on="on"
                                                    :style="'background-color:' + duration.color + ';width:' + ((row[duration.id] / row.totalDuration) * 100) + '%;'">
                                                </div>
                                            </template>
                                            <span><strong>{{ duration.text }}</strong><br />{{
                                                $moment.utc($moment.duration(row[duration.id],
                                                    'minutes').as('milliseconds')).format('HH:mm')
                                            }}</span>
                                        </v-tooltip>

                                    </div>
                                </div>
                            </th>
                            <template v-for="(header, date) in headers">
                                <td v-for="(child, childDate) in header.children" :key="date + '_' + childDate"
                                    :style="row.visible ? '' : 'opacity:0.4'"></td>
                            </template>
                        </tr>
                        <div v-if="nowShow" class="eod-scheduler__now" :style="'left:' + nowLeft + 'px;top:0;bottom:0;'">
                        </div>
                    </tbody>
                </table>

                <div style="content:'';clear:both;"></div>
                <template v-for="(item, index) in scheduledItems">
                    <eod-scheduler-item v-if="!item.isDepot && !item.isBreakEvent && !item.isTransport"
                        :key="index + '_' + item.id" :value="item" :itemStyle="{ background: item.route.color }"
                        :rowIndex="item.rowIndex" :colWidth="colWidth" :rowHeight="rowHeight" :offsetY="headerHeight"
                        :offsetX="titleWidth" :columnIndex="item.columnIndex" :height="item.height" :width="item.width"
                        :loading="item.loading" @resize="(newWidth) => resize(newWidth, index)"
                        @resizeEnd="() => resized(index)" @drag="(event) => drag(event, index)"
                        @dragEnd="(event, itemPos) => dragEnd(event, index, itemPos)"
                        @acceptItem="val => $emit('acceptItem', val)" @cancelItem="val => $emit('cancelItem', val)"
                        @contextmenu="(event) => contextMenu(event, index)" :locking="lockJobLoading[item.id]"
                        @lock="(locked) => lockJob(locked, index, item.id)">
                    </eod-scheduler-item>
                    <eod-scheduler-depot v-if="item.isDepot" :key="index + '_' + item.id" :value="item"
                        :rowIndex="item.rowIndex" :colWidth="colWidth" :rowHeight="rowHeight" :offsetY="headerHeight"
                        :offsetX="titleWidth" :columnIndex="item.columnIndex" :height="item.height"
                        :width="item.width"></eod-scheduler-depot>
                    <eod-scheduler-break v-if="item.isBreakEvent" :key="index + '_' + item.id" :value="item"
                        :rowIndex="item.rowIndex" :colWidth="colWidth" :rowHeight="rowHeight" :offsetY="headerHeight"
                        :offsetX="titleWidth" :columnIndex="item.columnIndex" :height="item.height"
                        :width="item.width"></eod-scheduler-break>
                    <eod-scheduler-transport v-if="item.isTransport" :key="index + '_' + item.id" :value="item"
                        :rowIndex="item.rowIndex" :colWidth="colWidth" :rowHeight="rowHeight" :offsetY="headerHeight"
                        :offsetX="titleWidth" :columnIndex="item.columnIndex" :height="item.height"
                        :width="item.width"></eod-scheduler-transport>
                </template>

                <v-menu v-model="showContextMenu" :position-x="contextMenuX" :position-y="contextMenuY" absolute offset-y>
                    <v-list dense>
                        <v-list-item @click="deleteContextMenuItem">
                            <v-list-item-icon>
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Verwijderen</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <div v-if="loader.show" :style="loader.style" class="eod-scheduler__spinner">
                    <v-progress-circular :size="20" color="primary" indeterminate></v-progress-circular>
                </div>
                <eod-workflow-dialog v-if="showWorkflowDialog" v-model="showWorkflowDialog" :workflow="activeWorkflow"
                    @saved="showWorkflowDialog = false; $emit('refresh');"></eod-workflow-dialog>
            </template>
            <div v-else-if="rows && rows.length <= 0" id="eod-scheduler__drop-new-route" class="px-4 py-4"
                :class="{ 'hovering': hoveringDopZone }">
                <eod-loader v-model="loadingDopZone"></eod-loader>
                Er zijn geen routes gevonden voor de gekozen datum.<br>
                Sleep een taak in dit vak om een route aan te maken voor de gekozen datum.
            </div>

        </div>
    </div>
</template>
<style scoped lang="scss">
.eod-scheduler {
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #FFFFFF;
    display: flex;

    #eod-scheduler__body {
        position: relative;

        tr,
        th,
        td {
            height: 148px;
        }
    }

    #eod-scheduler__drop-new-route {

        left: 0;
        top: 0;
        height: 100%;
        width: 100%;

        &.hovering {
            background: #dddddd;
        }
    }

    .eod-duration__item {
        opacity: 0.8;

        &:hover {
            opacity: 1;
        }
    }


    .eod-scheduler__now {
        width: 1px;
        background-color: var(--v-secondary-base);
        position: absolute;
        z-index: 4;
        transition: .2s all;
        opacity: 1;

        &:after {
            content: '';
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid var(--v-secondary-base);
            position: absolute;
            left: -4px;
            top: -4px;
        }
    }

    >.eod-scheduler__wrapper {
        position: relative;
        width: 100%;

        .eod-scheduler__spinner {
            position: absolute;
            background: #eeeeee;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        >.eod-scheduler__table {

            &,
            thead,
            tbody,
            tfoot,
            tr,
            th,
            td {
                width: auto;
                height: auto;
                margin: 0;
                padding: 0;
                border-collapse: inherit;
                border-spacing: 0;
                border-color: inherit;
                vertical-align: inherit;
                text-align: left;
                font-weight: inherit;
                -webkit-border-horizontal-spacing: 0;
                -webkit-border-vertical-spacing: 0;
            }

            min-height: 100%;

            thead {
                th {
                    font-weight: normal;
                    color: #444444;

                    &:first-child {
                        z-index: 5;
                    }
                }
            }

            tbody {
                th {
                    z-index: 5;
                }
            }

            .eod-scheduler__row-title {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                display: block;
                padding: 5px;
                font-size: .8rem;
            }

            td {
                border-right: 1px solid #eeeeee;
                border-bottom: 1px solid #eeeeee;
                background: #F8F8F8;

                &.droppable {
                    background: #dddddd;
                }
            }

            th {
                padding: 2px 5px;
                border-right: 1px solid #eeeeee;
                border-bottom: 1px solid #eeeeee;
                /*min-width: 80px;*/
                background: #ffffff;
                vertical-align: middle;

                &.col-header {
                    .content {
                        min-width: 180px;
                        max-width: 220px;

                        &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                        }

                        >span {
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }

        }

    }
}
</style>
<script>
import eodSchedulerItem from './eod-scheduler-item';
import eodSchedulerDepot from './eod-scheduler-depot';
import eodSchedulerBreak from './eod-scheduler-break';
import eodSchedulerTransport from './eod-scheduler-transport';
import eodWorkflowDialog from './eod-workflow-dialog.vue';
import eodLoader from './eod-loader.vue';


export default {
    components: {
        'eod-scheduler-item': eodSchedulerItem,
        'eod-workflow-dialog': eodWorkflowDialog,
        eodLoader,
        eodSchedulerDepot,
        eodSchedulerBreak,
        eodSchedulerTransport,
    },
    props: {
        value: Array,
        from: Object,
        until: Object,
    },
    data() {
        return {
            durations: [
                {
                    id: 'workDuration',
                    color: '#2DC443',
                    text: 'Werk'
                },
                {
                    id: 'waitDuration',
                    color: '#C48F2D',
                    text: 'Wachten'
                },
                {
                    id: 'breakDuration',
                    color: '#C42D74',
                    text: 'Pauze'
                },
                {
                    id: 'transportDuration',
                    color: '#A22DC4',
                    text: 'Verplaatsing'
                },
                {
                    id: 'remainingDuration',
                    color: '#C0C42D',
                    text: 'Beschikbaar'
                },
            ],
            showAll: true,
            rows: [],
            nowLeft: -2,
            nowShow: false,
            showWorkflowDialog: false,
            activeWorkflow: null,
            headers: {},
            allHeaders: [],
            scheduledItems: [],
            showContextMenu: false,
            contextMenuItemIndex: null,
            contextMenuX: 0,
            contextMenuY: 0,
            colWidth: 40,
            config: {
                header: {
                    steps: {
                        value: 15,
                        type: 'minutes',
                    },
                    groups: {
                        format: 'DD/MM',
                        children: {
                            format: 'HH:mm'
                        }
                    }
                }
            },
            headerHeight: 0,
            titleWidth: 0,
            rowHeight: 0,
            resizeTimeout: null,
            nowMarkerInterval: null,
            dragOffset: null,
            loader: {
                show: false,
                style: ''
            },
            hoveringDopZone: false,
            loadingDopZone: false,
            unlockLoading: false,
            lockLoading: false,
            lockJobLoading: {},
        }
    },
    mounted() {
        this.refresh().then(() => {
            this.scrollToNow();
        });

        this.nowMarkerInterval = setInterval(() => {
            this.positionNowMarker();
        }, 60000);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeWindow);
        clearInterval(this.nowMarkerInterval);
    },
    methods: {
        getRouteById(routeId) {
            for (let i = 0; i < this.value.length; i++) {
                if (this.value[i].id == routeId) {
                    return this.value[i];
                }
            }

            return null;
        },
        async lockRoute(routeId) {
            this.lockLoading = true;
            let route = this.getRouteById(routeId);
            if (route.tasks) {
                for (let i = 0; i < route.tasks.length; i++) {
                    const task = route.tasks[i];
                    await this.$eod.save('Task', {
                        id: task.id,
                        dueRequestDate: this.$moment.tz(this.from, 'Europe/Brussels').format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
                        mustBePlannedOnRequestDate: true
                    })
                }
            }

            this.lockLoading = false;
            this.$emit('refresh');
        },
        async unlockRoute(routeId) {
            this.unlockLoading = true;
            let route = this.getRouteById(routeId);
            if (route.tasks) {
                for (let i = 0; i < route.tasks.length; i++) {
                    const task = route.tasks[i];
                    await this.$eod.save('Task', {
                        id: task.id,
                        mustBePlannedOnRequestDate: false
                    })
                }
            }

            this.unlockLoading = false;
            this.$emit('refresh');
        },
        lockJob(locked, index, jobId) {
            this.lockJobLoading[jobId] = true;
            this.$forceUpdate();
            const item = this.scheduledItems[index];
            this.$eod.save('Task', {
                id: item.id,
                dueRequestDate: this.$moment.tz(this.from, 'Europe/Brussels').format('YYYY-MM-DD[T]HH:mm:ss[.000Z]'),
                mustBePlannedOnRequestDate: locked
            }).then(() => {
                this.lockJobLoading[jobId] = false;
                this.$emit('refresh');
            });
        },
        routeClicked(routeId) {

            let routes = this.value;

            for (let i = 0; i < routes.length; i++) {
                if (routes[i].id == routeId) {
                    routes[i].visible = true;

                    for (let j = 0; j < routes[i].jobs.length; j++) {
                        routes[i].jobs[j].visible = true;
                    }
                } else {
                    routes[i].visible = false;
                    for (let j = 0; j < routes[i].jobs.length; j++) {
                        routes[i].jobs[j].visible = false;
                    }
                }
            }

            this.$emit('updateRoutes', routes);
        },
        toggleView(routeId) {

            let routes = this.value;

            if (routeId == 'all') {

                const visible = !this.showAll;

                for (let i = 0; i < routes.length; i++) {
                    routes[i].visible = visible;
                    for (let j = 0; j < routes[i].jobs.length; j++) {
                        routes[i].jobs[j].visible = visible;
                    }
                }

                this.showAll = visible;

            } else {
                for (let i = 0; i < routes.length; i++) {
                    const route = routes[i];
                    if (route.id == routeId) {
                        routes[i].visible = !routes[i].visible;

                        for (let j = 0; j < routes[i].jobs.length; j++) {
                            routes[i].jobs[j].visible = routes[i].visible;
                        }

                        break;
                    }
                }
            }

            this.$emit('updateRoutes', routes);
        },
        contextMenu(e, index) {
            e.preventDefault();
            this.showContextMenu = false;
            this.contextMenuItemIndex = index;
            this.contextMenuX = e.clientX;
            this.contextMenuY = e.clientY;
            this.$nextTick(() => {
                this.showContextMenu = true;
            });
        },
        deleteContextMenuItem() {
            const item = this.scheduledItems[this.contextMenuItemIndex];

            this.$eod.workflowReturnToPlan(item.parent.id)
                .then(response => {
                    let items = this.value;
                    for (let i = 0; i < items.length; i++) {
                        const route = items[i];
                        for (let j = 0; j < route.jobs.length; j++) {
                            const job = route.jobs[j];
                            if (job.id == item.id) {
                                items[i].jobs.splice(j, 1);

                                this.$emit('input', items);
                                this.$emit('deleted', item);

                                this.scheduledItems.splice(this.contextMenuItemIndex, 1);

                                this.$emit('refresh');

                                return false;
                            }
                        }

                    }

                });
        },
        draggingNewItem(e, item) {
            const tableBody = document.getElementById('eod-scheduler__body');

            if (tableBody) {
                const containerX = (tableBody.getBoundingClientRect().left * -1) + e.pageX - this.titleWidth;
                const xIndex = Math.floor(containerX / this.colWidth);

                const containerY = (tableBody.getBoundingClientRect().top * -1) + e.pageY - window.pageYOffset;
                const yIndex = Math.floor(containerY / this.rowHeight);

                let rows = tableBody.children;
                for (let i = 0; i < rows.length; i++) {
                    const row = rows[i];
                    for (let j = 0; j < row.children.length; j++) {
                        rows[i].children[j].classList.remove('droppable');
                    }
                }

                if (tableBody.children[yIndex] && tableBody.children[yIndex].children[xIndex + 1]) {
                    tableBody.children[yIndex].children[xIndex + 1].classList.add('droppable');
                }
            }

            const dropZoneElement = document.getElementById('eod-scheduler__drop-new-route');

            if (dropZoneElement) {
                const dropZoneBoundingRect = dropZoneElement.getBoundingClientRect();
                const droppedXInside = ((dropZoneBoundingRect.left * -1) + e.pageX) > 0 && e.pageX < (dropZoneBoundingRect.left + dropZoneBoundingRect.width);
                const droppedYInside = ((dropZoneBoundingRect.top * -1) + e.pageY) > 0 && e.pageY < (dropZoneBoundingRect.top + dropZoneBoundingRect.height);
                const droppedInside = droppedXInside && droppedYInside;
                if (droppedInside) {
                    this.hoveringDopZone = true;
                } else {
                    this.hoveringDopZone = false;
                }
            }
        },
        showLoader(xIndex, yIndex) {
            let style = 'width:' + this.colWidth + 'px;height:' + this.rowHeight + 'px;';

            style += 'left:' + ((xIndex * this.colWidth) + this.titleWidth) + 'px;';
            style += 'top:' + ((yIndex * this.rowHeight) + this.headerHeight + (yIndex / 2.6)) + 'px;'; // + yIndex for 1px border

            this.loader.style = style;
            this.loader.show = true;
        },
        hideLoader() {
            this.loader.show = false;
        },
        cancelItem(itemIndex) {
            const item = this.scheduledItems[itemIndex];
            this.$emit('cancelItem', item);
        },
        acceptItem(itemIndex) {
            const item = this.scheduledItems[itemIndex];
            this.$emit('acceptItem', item);
        },
        draggingNewItemEnd(e, item) {

            const dropZoneElement = document.getElementById('eod-scheduler__drop-new-route');
            if (dropZoneElement) {
                const dropZoneBoundingRect = dropZoneElement.getBoundingClientRect();
                const droppedXInside = ((dropZoneBoundingRect.left * -1) + e.pageX) > 0 && e.pageX < (dropZoneBoundingRect.left + dropZoneBoundingRect.width);
                const droppedYInside = ((dropZoneBoundingRect.top * -1) + e.pageY) > 0 && e.pageY < (dropZoneBoundingRect.top + dropZoneBoundingRect.height);
                const droppedInside = droppedXInside && droppedYInside;
                if (droppedInside) {
                    this.hoveringDopZone = false;
                    this.loadingDopZone = true;

                    return this.$eod.submitWorkflow(item.parent.id)
                        .then(response => {
                            return this.$eod.save('OptimizeTask', {
                                id: response.id,
                                dueRequestDate: this.$moment(this.from).format('YYYY-MM-DD'),
                                mustBePlannedOnRequestDate: true,
                                estimatedDuration: 3600000
                            }, {
                                update: {
                                    returnfields: ['dueRequestDate', 'dueBeforeDate', 'dueAfterDate']
                                }
                            });
                        }).then((response) => {
                            if (response.data.data.updateOptimizeTask) {
                                return this.$eod.save('Task', {
                                    id: item.parent.id,
                                    dueRequestDate: response.data.data.updateOptimizeTask.dueRequestDate,
                                    dueBeforeDate: response.data.data.updateOptimizeTask.dueBeforeDate,
                                    dueAfterDate: response.data.data.updateOptimizeTask.dueAfterDate,
                                    mustBePlannedOnRequestDate: true
                                });
                            }
                            return false;
                        }).then(() => {
                            this.loadingDopZone = false;
                            this.$emit('dropped', item);
                            this.$emit('refresh');
                        }).catch(e => {
                            console.log('error ', e);
                        }).finally(() => {
                            this.loadingDopZone = false;
                        });
                }
            }

            const schedulerElement = document.getElementById('eod-scheduler');
            if (schedulerElement) {
                const schedulerBounding = schedulerElement.getBoundingClientRect();
                const droppedXInside = ((schedulerBounding.left * -1) + e.pageX) > 0 && e.pageX < (schedulerBounding.left + schedulerBounding.width);
                const droppedYInside = ((schedulerBounding.top * -1) + e.pageY) > 0 && e.pageY < (schedulerBounding.top + schedulerBounding.height);
                const droppedInside = droppedXInside && droppedYInside;

                if (!droppedInside) {
                    // Element is not dropped on scheduler
                    return false;
                }
            }

            const tableBody = document.getElementById('eod-scheduler__body');
            if (tableBody) {
                const containerX = (tableBody.getBoundingClientRect().left * -1) + e.pageX - this.titleWidth;
                const xIndex = Math.floor(containerX / this.colWidth);

                const containerY = (tableBody.getBoundingClientRect().top * -1) + e.pageY - window.pageYOffset;
                const yIndex = Math.floor(containerY / this.rowHeight);

                let rows = tableBody.children;
                for (let i = 0; i < rows.length; i++) {
                    const row = rows[i];
                    for (let j = 0; j < row.children.length; j++) {
                        rows[i].children[j].classList.remove('droppable');
                    }
                }

                if (tableBody.children[yIndex] && tableBody.children[yIndex].children[xIndex + 1]) {
                    this.showLoader(xIndex, yIndex);

                    const dueAfterDate = this.allHeaders[xIndex];
                    const dueBeforeDate = this.allHeaders[xIndex + 4];
                    const route = this.rows[yIndex];

                    const momentAfter = this.$moment(dueAfterDate);
                    const momentBefore = this.$moment(dueBeforeDate);
                    const duration = momentBefore.diff(momentAfter, 'miliseconds', true);

                    this.$eod.submitWorkflow(item.parent.id)
                        .then(response => {

                            let responsibleUsers = [];
                            for (let i = 0; i < route.responsibleUsers.length; i++) {
                                const usr = route.responsibleUsers[i];
                                responsibleUsers.push({ id: usr.id });
                            }

                            let saveData = {
                                id: response.id,
                                dueAfterDate: dueAfterDate,
                                dueBeforeDate: dueBeforeDate,
                                estimatedDuration: duration,
                                routeId: route.id,
                                mustBePlannedOnRequestDate: true,
                                dueRequestDate: dueAfterDate
                            };

                            return this.$eod.save('OptimizeTask', saveData, {
                                update: {
                                    returnfields: ['id', 'name', 'isJobActive', 'stepType', 'category', 'statusCode', 'route{id}', 'dueBeforeDate', 'dueAfterDate', 'responsibleUsers{id}', 'parent{id name project{id name} service{id name} company{id name} taskType{id name configuration{settings{key value}}}}']
                                }
                            }).then(response => {

                                if (response.data.data) {
                                    return response.data.data.updateOptimizeTask;
                                } else if (response.data.errors) {
                                    return this.$eod.save('Task', saveData, {
                                        update: {
                                            returnfields: ['id', 'name', 'isJobActive', 'stepType', 'category', 'statusCode', 'route{id}', 'dueBeforeDate', 'dueAfterDate', 'responsibleUsers{id}', 'parent{id name project{id name} service{id name} company{id name} taskType{id name configuration{settings{key value}}}}']
                                        }
                                    }).then(response => {
                                        if (response.data.data) {
                                            return response.data.data.updateTask;
                                        }
                                        return null;
                                    });
                                }

                                return null;
                            });
                        })
                        .then(task => {
                            if (task) {
                                this.addTask(task);
                                this.$emit('dropped', item);
                            }

                            this.$emit('refresh');
                        }).finally(() => {
                            this.hideLoader();
                        });
                }
            }
        },
        resizeWindow() {
            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout);
            }

            this.resizeTimeout = setTimeout(() => {
                this.setTableDimensions();
                this.$nextTick(this.calculateItems);
            }, 200);
        },
        resize(newWidth, itemIndex) {
            this.scheduledItems[itemIndex].width = newWidth;
        },
        refresh() {
            return this.getRows().then(() => {
                return this.loadHeaders();
            }).then(() => {
                return this.setTableDimensions();
            }).then(() => {
                return this.calculateItems();
            });
        },

        resized(itemIndex) {
            const item = this.scheduledItems[itemIndex];
            const dueAfterDate = this.allHeaders[item.columnIndex];
            const dueBeforeDate = this.allHeaders[item.columnIndex + item.width];

            const momentAfter = this.$moment(dueAfterDate);
            const momentBefore = this.$moment(dueBeforeDate);
            const duration = momentBefore.diff(momentAfter, 'miliseconds', true);

            let saveData = {
                id: item.id,
                dueAfterDate: dueAfterDate,
                dueBeforeDate: dueBeforeDate,
                estimatedDuration: duration,
                mustBePlannedOnRequestDate: true,
                dueRequestDate: dueAfterDate
            };

            this.$eod.save('OptimizeTask', saveData, {
                update: {
                    returnfields: ['id responsibleUsers{id} dueAfterDate dueBeforeDate']
                }
            })
                .then(response => {
                    if (response.data.data) {
                        this.refreshTask(response.data.data.updateOptimizeTask);
                    } else if (response.data.errors) {
                        this.$eod.save('Task', saveData, {
                            update: {
                                returnfields: ['id responsibleUsers{id} dueAfterDate dueBeforeDate']
                            }
                        }).then(response => {
                            if (response.data.data) {
                                this.refreshTask(response.data.data.updateTask);
                            }
                        });
                    }
                });
        },
        drag(e, itemIndex) {

            const containerX = (document.getElementById('eod-scheduler__body').getBoundingClientRect().left * -1) + e.pageX - this.titleWidth;
            const xIndex = Math.floor(containerX / this.colWidth);

            if (this.dragOffset == null) {
                this.dragOffset = xIndex - this.scheduledItems[itemIndex].columnIndex;
            }

            const newIndex = xIndex - this.dragOffset;

            if (newIndex >= 0) {
                this.scheduledItems[itemIndex].columnIndex = newIndex;
            } else {
                this.scheduledItems[itemIndex].columnIndex = 0;
            }

            const containerY = (document.getElementById('eod-scheduler__body').getBoundingClientRect().top * -1) + e.pageY - window.pageYOffset;
            const yIndex = Math.floor(containerY / this.rowHeight);

            if (yIndex >= 0) {
                this.scheduledItems[itemIndex].rowIndex = yIndex;
            } else {
                this.scheduledItems[itemIndex].rowIndex = 0;
            }
        },
        dragEnd(e, itemIndex, itemPos) {

            this.dragOffset = null;

            const item = this.scheduledItems[itemIndex];

            if (itemPos.originalColumnIndex != item.columnIndex || itemPos.originalRowIndex != item.rowIndex) {
                const dueAfterDate = this.allHeaders[item.columnIndex];
                const dueBeforeDate = this.allHeaders[item.columnIndex + item.width];
                const newRoute = this.rows[item.rowIndex];

                const momentAfter = this.$moment(dueAfterDate);
                const momentBefore = this.$moment(dueBeforeDate);
                const duration = momentBefore.diff(momentAfter, 'miliseconds', true);

                let saveData = {
                    id: item.id,
                    estimatedDuration: duration,
                    dueAfterDate: dueAfterDate,
                    dueBeforeDate: dueBeforeDate,
                    routeId: newRoute.id,
                    mustBePlannedOnRequestDate: true,
                    dueRequestDate: dueAfterDate
                };

                this.$eod.save('OptimizeTask', saveData, {
                    update: {
                        returnfields: ['id responsibleUsers{id} dueAfterDate dueBeforeDate']
                    }
                })
                    .then(response => {
                        if (response.data.data) {
                            this.refreshTask(response.data.data.updateOptimizeTask);
                        } else if (response.data.errors) {
                            this.$eod.save('Task', saveData, {
                                update: {
                                    returnfields: ['id responsibleUsers{id} dueAfterDate dueBeforeDate']
                                }
                            }).then(response => {
                                if (response.data.data) {
                                    this.refreshTask(response.data.data.updateTask);
                                }
                            });
                        }
                    });
            } else {
                this.activeWorkflow = item.parent;
                this.showWorkflowDialog = true;
            }
        },
        addTask(item) {
            let items = this.value;
            for (let i = 0; i < items.length; i++) {
                const route = items[i];
                if (route.id == item.route.id) {
                    items[i].jobs.push(item);
                }
            }

            this.$emit('input', items);
            this.calculateItems();
        },
        refreshTask(updateItem) {

            let items = this.value;
            for (let i = 0; i < items.length; i++) {
                const route = items[i];
                for (let j = 0; j < route.jobs.length; j++) {
                    const job = route.jobs[j];
                    if (job.id == updateItem.id) {
                        items[i].jobs[j] = {
                            ...job,
                            ...updateItem
                        }
                    }
                }

            }

            this.$emit('input', items);
            this.$emit('updateRoutes', items);
            this.$emit('refresh');

            //this.calculateItems();
        },
        setTableDimensions() {
            return new Promise((resolve, reject) => {
                const header = window.document.getElementById('eod-scheduler__header');
                if (header) {
                    this.headerHeight = header.offsetHeight;
                }

                const body = window.document.getElementById('eod-scheduler__body');
                if (body) {
                    this.titleWidth = body.childNodes[0].childNodes[0].offsetWidth;
                    this.rowHeight = body.childNodes[0].offsetHeight;
                }

                this.positionNowMarker();

                resolve();
            });

        },
        loadHeaders() {
            return new Promise((resolve, reject) => {
                this.headers = this.getHeaders();

                resolve();
            });

        },
        getNextAvailableIndex(taskIndexes, indexItems) {
            let indexes = [];
            for (const itemId in taskIndexes) {
                const element = taskIndexes[itemId];
                for (let i = 0; i < indexItems.length; i++) {
                    const indexItemId = indexItems[i];
                    if (indexItemId == itemId) {
                        indexes.push(element.index);
                    }
                }

            }

            for (let i = 0; i < indexItems.length; i++) {
                if (!indexes.includes(i)) {
                    return i;
                }
            }
        },
        getIndexHeight(itemsPerCell) {

            let indexesPerRow = {};
            for (const rowIndex in itemsPerCell) {
                const rowColumns = itemsPerCell[rowIndex];
                let taskIndexes = {};

                for (const columnIndex in rowColumns) {
                    const itemIds = rowColumns[columnIndex];
                    let maxHeight = itemIds.length;

                    // Check if any task of the cell already has an index and height
                    for (let i = 0; i < itemIds.length; i++) {
                        const itemId = itemIds[i];

                        if (taskIndexes[itemId]) {
                            if (maxHeight < taskIndexes[itemId].height) {
                                maxHeight = taskIndexes[itemId].height;
                            }
                            taskIndexes[itemId].height = maxHeight;
                        } else {
                            taskIndexes[itemId] = {
                                index: this.getNextAvailableIndex(taskIndexes, itemIds),
                                height: maxHeight > itemIds.length ? maxHeight : itemIds.length
                            };
                        }
                    }
                }

                indexesPerRow[rowIndex] = taskIndexes;
            }

            return indexesPerRow;
        },
        getRows() {
            return new Promise((resolve, reject) => {
                let rows = [];

                if (this.value) {
                    for (let rowIndex = 0; rowIndex < this.value.length; rowIndex++) {
                        const val = this.value[rowIndex];
                        rows.push(val);
                    }
                }

                this.rows = rows;

                resolve(rows);
            });
        },
        calculateItems() {

            let items = [];

            if (this.value) {

                for (let rowIndex = 0; rowIndex < this.value.length; rowIndex++) {
                    const val = this.value[rowIndex];

                    let jobs = val.jobs;

                    // calculate amount of events per cell
                    let itemsPerCell = {};
                    for (let i = 0; i < jobs.length; i++) {
                        const job = jobs[i];
                        const columnData = this.getColumnData(job);
                        const rowData = [rowIndex];

                        jobs[i].columnData = columnData;
                        jobs[i].rowData = rowData;

                        if (!itemsPerCell[rowIndex]) {
                            itemsPerCell[rowIndex] = {};
                        }

                        const end = columnData.start + columnData.width;
                        for (let columnIndex = columnData.start; columnIndex < end; columnIndex++) {
                            if (!itemsPerCell[rowIndex][columnIndex]) {
                                itemsPerCell[rowIndex][columnIndex] = [job.id];
                            } else {
                                itemsPerCell[rowIndex][columnIndex].push(job.id);
                            }
                        }
                    }

                    let indexHeightPerCell = this.getIndexHeight(itemsPerCell);

                    for (let i = 0; i < jobs.length; i++) {
                        const job = jobs[i];
                        for (let j = 0; j < job.rowData.length; j++) {
                            const rowIndex = job.rowData[j];
                            const itemsData = indexHeightPerCell[rowIndex];

                            if (itemsData[job.id]) {
                                items.push({
                                    ...job,
                                    route: val,
                                    rowIndex: rowIndex,
                                    width: job.columnData.width,
                                    columnIndex: job.columnData.start,
                                    height: this.rowHeight / itemsData[job.id].height,
                                    heightIndex: itemsData[job.id].index
                                });
                            }
                        }
                    }

                }
            }

            this.scheduledItems = items;
        },
        positionNowMarker() {
            // Set now marker
            if (this.$moment().isBetween(this.from, this.until)) {
                const nowLeft = this.titleWidth + ((this.$moment().diff(this.from, 'minutes') / this.config.header.steps.value) * this.colWidth);
                this.nowLeft = nowLeft;
                this.nowShow = true;
            } else {
                this.nowShow = false;
            }
        },
        getClosest(number, round) {
            let remainder = number % round;
            if (remainder > 7.5) {
                return round - remainder;
            }

            return -1 * remainder;
        },
        getColumnData(job) {
            let start = null;
            let width = 0;

            const from = this.$moment(job.dueAfterDate);
            // Round to nearest 15min
            from.add(this.getClosest(from.minute(), this.config.header.steps.value), "minutes");

            const until = this.$moment(job.dueBeforeDate);
            // Round to nearest 15min
            until.add(this.getClosest(until.minute(), this.config.header.steps.value), "minutes");

            for (let i = 0; i < this.allHeaders.length; i++) {
                const headerDate = this.$moment(this.allHeaders[i]);

                if (from.diff(headerDate, 'hours') == 0 && Math.abs(from.diff(headerDate, 'minutes')) <= 8) {
                    start = i;
                }

                if (start != null && until > headerDate) {
                    width++;
                }
            }

            return {
                start: start,
                width: width
            }
        },

        getSubHeaders(header) {
            let subHeaders = [];

            for (const date in header.children) {
                const childDate = header.children[date];
                const m = this.$moment(childDate);

                if (this.colWidth <= 10) {
                    if ([0, 4, 8, 12, 16, 20, 24].includes(m.hours()) && m.minutes() == 0) {
                        subHeaders.push({
                            date: m.format(this.config.header.groups.children.format),
                            colspan: 16,
                            width: this.colWidth * 16
                        });
                    }
                } else if (this.colWidth <= 20) {
                    if (m.minutes() == 0) {
                        subHeaders.push({
                            date: m.format(this.config.header.groups.children.format),
                            colspan: 4,
                            width: this.colWidth * 4
                        });
                    }
                } else {
                    if ([0, 30].includes(m.minutes())) {
                        subHeaders.push({
                            date: m.format(this.config.header.groups.children.format),
                            colspan: 2,
                            width: this.colWidth * 2
                        });
                    }
                }
            }

            this.positionNowMarker();

            return subHeaders;
        },
        scrollToNow() {
            if (window.document.getElementById('eod-scheduler')) {
                if (this.from.isSame(new Date(), "day")) {
                    const wrapperWidth = window.document.getElementById('eod-scheduler').offsetWidth;
                    const nowLeft = this.titleWidth + ((this.$moment().diff(this.from, 'minutes') / this.config.header.steps.value) * this.colWidth);
                    const scrollLeft = nowLeft - (wrapperWidth / 2);
                    document.getElementById('eod-scheduler').scrollLeft = scrollLeft;
                } else {
                    const wrapperWidth = window.document.getElementById('eod-scheduler').offsetWidth;
                    document.getElementById('eod-scheduler').scrollLeft = wrapperWidth / 2;
                }

            }
        },
        getHeaders() {
            let headers = {};
            this.allHeaders = [];

            for (var m = this.$moment(this.from).startOf('days'); m.diff(this.until, this.config.header.steps.type) < 0; m.add(this.config.header.steps.value, this.config.header.steps.type)) {

                let header = headers;
                let group = this.config.header.groups;

                while (group && group.format) {
                    const name = m.format(group.format);
                    if (!header[name]) {
                        if (group.children) {
                            header[name] = { children: {} };
                        } else {
                            header[name] = m.toISOString();
                            this.allHeaders.push(m.startOf('minute').toISOString());
                        }
                    }

                    if (group.children) {
                        header = header[name].children;

                    }

                    group = group.children;

                }
            }

            // Check difference between from and until
            const dayCount = Object.keys(headers).length;
            if (dayCount <= 1) {
                this.colWidth = 30;
            } else if (dayCount <= 8) {
                this.colWidth = 20;
            } else {
                this.colWidth = 10;
            }

            return headers;
        }
    }
}
</script>
