<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="auto">
          <v-tabs show-arrows background-color="transparent" color="primary" height="30" v-model="activeTab">
            <v-tab v-for="(tab, index) in visibleTabs" :key="index" class="px-2">{{
              tab.title
            }}<v-btn v-if="!tab.id && index == activeTab" class="ml-2" @click.stop="editTab(tab.index)" x-small dark
                icon><v-icon small>mdi-pencil-outline</v-icon></v-btn>
              <v-btn v-if="!tab.id && index == activeTab" class="ml-2" @click.stop="deleteTab(tab.index)" color="error"
                x-small rounded depressed><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col cols="auto">
          <v-btn small height="30" color="primary" outlined @click="addTab" href="#">
            <v-icon size="18">mdi-tab-plus</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="auto" class="ms-auto">
          <v-btn class="btn-popup" color="primary" light small height="30" @click="openPopup">
            <v-icon size="18">mdi-launch</v-icon>&nbsp;
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-tabs-items v-model="activeTab">
      <v-tab-item v-for="(tab, index) in visibleTabs" :key="tab.title" :transition="false">
        <div class="px-4">
          <v-row v-if="!tab.id">
            <v-col>
              <eod-search v-model="user.configuration.events.tabs[tab.index].filters" flat topbar
                :config="Event.searchConfig()" objectType="EVENT" templateType="FILTER" @update="saveTabs()"></eod-search>
            </v-col>
            <v-col cols="auto">
              <eod-time-selector v-model="user.configuration.events.tabs[tab.index].timeFilter" :single-date="false"
                :arrows="false" @input="saveTabs()"></eod-time-selector>
            </v-col>
          </v-row>
        </div>
        <eod-events-drawer-table v-if="index == activeTab" ref="eventsTable" :height="height" :filters="tab.filters" :timeFilter="tab.timeFilter"
          :activeHeaders="tab.activeHeaders" @editItem="editItem"
          @activeHeadersChanged="val => { if (user.configuration.events.tabs[index]) { user.configuration.events.tabs[index].activeHeaders = val; saveTabs() } }"></eod-events-drawer-table>
      </v-tab-item>
    </v-tabs-items>

    <eod-delete-dialog v-model="deleteTabDialog" :item="newTab" @delete="deleteTabAction"></eod-delete-dialog>
    <eod-dialog v-model="addTabDialog" title="Tab toevoegen" icon="mdi-tab-plus" iconColor="primary"
      @okAction="addTabAction" okText="Opslaan">
      <v-text-field class="mt-4" required outlined dense label="Naam*" v-model="newTab.title"></v-text-field>
    </eod-dialog>

    <eod-dialog v-if="eventsDialogShown" v-model="eventsDialogShown" title="Event aanpassen" :width="1000" icon="mdi-plus"
      iconColor="primary">
      <div class="pt-4">
      <eod-event ref="eventForm" v-model="eventsDialogData"></eod-event>
    </div>
      <template v-slot:actions>
        <v-btn text @click="eventsDialogShown = false">Sluiten</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="eventsDialogData.id && !eventsDialogData.alarmTemplate" rounded depressed color="error"
          @click="deleteEventsDialogShown = true">Verwijderen</v-btn>
        <v-btn color="primary" depressed @click="saveEvent" rounded><v-icon
            left>mdi-content-save-outline</v-icon>Opslaan</v-btn>
      </template>
    
    </eod-dialog>
    <eod-delete-dialog v-if="deleteEventsDialogShown" v-model="deleteEventsDialogShown" :item="{ name: 'event' }"
      title="Event verwijderen" @delete="deleteEvent">

    </eod-delete-dialog>
  </div>
</template>

<script>
import eodEventsDrawerTable from './eod-events-drawer-table';
import eodEventsTableCompact from './eod-events-table-compact';
import eodEventsSidebar from './eod-events-sidebar';
import eodEvent from './eod-event';
import eodSearch from './eod-search.vue';
import eodTimeSelector from './eod-time-selector.vue';
import eodDialog from './eod-dialog.vue';
import eodDeleteDialog from './eod-delete-dialog.vue';
import Event from '../models/event';
import { User } from '../..';

export default {
  name: 'eod-events-drawer',

  components: {
    eodEventsDrawerTable,
    eodEventsTableCompact,
    eodEventsSidebar,
    eodSearch,
    eodTimeSelector,
    eodDialog,
    eodDeleteDialog,
    eodEvent
  },

  props: {
    height: {
      type: Number,
      default: null
    }
  },

  data: () => ({
    expanded: false,
    sidebarEvent: false,
    windowWidth: null,
    Event: Event,
    addTabDialog: false,
    deleteTabDialog: false,
    newTab: {
      title: null,
      filters: [],
      timeFilter: null,
      activeHeaders: null
    },
    activeTab: null,
    user: null,
    eventsDialogShown: false,
    eventsDialogData: null,
    deleteEventsDialogShown: false
  }),

  computed: {
    visibleTabs() {
      const tabs = [];

      if (this.user && this.user.configuration && this.user.configuration.events && this.user.configuration.events.tabs) {
        for (let i = 0; i < this.user.configuration.events.tabs.length; i++) {
          const tab = this.user.configuration.events.tabs[i];
          tab.index = i;
          tabs.push(tab);
        }
      }

      return tabs;
    },
    isMobile() {
      this.windowWidth; // Force recomputation when window width changes
      return window.outerWidth <= 600;
    }
  },

  created() {
    window.addEventListener("resize", this.windowResized);

    this.$eod.getById('user', this.$eod.getUser().id, ['id', 'configuration']).then(response => {
      const user = response.data.data.user;

      if (!user.configuration) {
        user.configuration = {};
      }

      if (!user.configuration.events) {
        user.configuration.events = {
          tabs: []
        };
      }

      if (!user.configuration.events.tabs[0] || user.configuration.events.tabs[0].id != 'active-alarms') {
        user.configuration.events.tabs.unshift({
          id: 'active-alarms',
          title: 'Actieve alarmen',
          timeFilter: null,
          activeHeaders: null
        });
      }

      user.configuration.events.tabs[0] = {
          ...user.configuration.events.tabs[0],
          filters: [
            {
              config: Event.getSearchFilter('status'),
              result: {
                id: "open",
                search_title: "Open",
                selected: false
              }
            },
            {
              config: Event.getSearchFilter('isMasked'),
              result: {
                id: "false",
                search_title: "Nee",
                selected: false
              }
            }
          ],
        }

      this.user = user;
    });

  },

  destroyed() {
    window.removeEventListener("resize", this.windowResized);
  },

  methods: {
    reloadData() {
      if (this.$refs.eventsTable) {
        this.$refs.eventsTable[0].loadEvents();
      }
    },
    editItem(event) {
      if (event.owner) {
        event.owner = new User(event.owner);
      }
      this.eventsDialogData = event;
      this.eventsDialogShown = true;
    },
    deleteEvent() {
      this.$eod.delete('Event', this.eventsDialogData.id).finally(() => {
        this.deleteEventsDialogShown = false;
        this.eventsDialogShown = false;
        this.eventsDialogData = null;
        this.reloadData();
      });
    },
    saveEvent() {
      if (this.$refs.eventForm) {
        if (!this.$refs.eventForm.validate()) {
          return false;
        }
      }

      if(this.eventsDialogData.alarmTemplate){
        this.$eod.save('Template', this.eventsDialogData.alarmTemplate);
      }

      const data = this.eventsDialogData.getSaveData();
      this.$eod.save('Event', data).then(response => {
        this.eventsDialogShown = false;
        this.eventsDialogData = null;
      }).then(() => {
        this.reloadData();
      });
    },
    saveTabs() {
      this.$eod.save('User', this.user);
    },
    openSidebar(event) {
      this.sidebarEvent = event;
    },

    sidebarClosed() {
      this.sidebarEvent = false;
    },

    windowResized(e) {
      this.windowWidth = window.outerWidth;
    },

    openPopup() {
      let height = window.screen.availHeight * 0.75;
      let width = window.screen.availWidth - 150;
      window.open('/events-popup?tab=' + this.visibleTabs[this.activeTab].index, 'targetWindow', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=' + width + ',height=' + height);

      return false;
    },
    deleteTab(tabIndex) {
      this.newTab = { ...this.user.configuration.events.tabs[tabIndex] };
      this.newTab.name = this.newTab.title;
      this.deleteTabDialog = true;
    },
    deleteTabAction() {
      this.user.configuration.events.tabs.splice(this.newTab.index, 1);
      this.saveTabs();
    },
    editTab(tabIndex) {
      this.newTab = { ...this.user.configuration.events.tabs[tabIndex] };
      this.newTab.edit = true;
      this.addTabDialog = true;
    },
    addTab() {
      this.newTab.edit = false;
      this.addTabDialog = true;
    },
    addTabAction(e) {
      if (this.newTab.edit) {
        delete this.newTab.edit;
        this.user.configuration.events.tabs[this.newTab.index] = { ...this.newTab };
      } else {
        delete this.newTab.edit;
        this.newTab.index = this.user.configuration.events.tabs.length - 1;
        this.user.configuration.events.tabs.push({ ...this.newTab });
      }

      this.newTab.title = null;
      this.addTabDialog = false;

      this.saveTabs();
    },
  },
}
</script>

<style lang="scss">
.v-application .btn-popup {
  min-width: 0 !important;
}
</style>