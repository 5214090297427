export default class Base{
    static searchConfig = [{
        'icon': 'mdi-format-text',
        'label': 'Naam',
        'type': 'property',
        'settings': {
            'fields': ['name'],
            'title': '{name}',
        },
        'filter': 'name'
    },
    ];
    
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }
    getSaveData() {
        let data = Object.assign({}, this);
        
        const relations = this.constructor.relations;
        if(relations && relations.oneToMany){
            for (let i = 0; i < relations.oneToMany.length; i++) {
                const relation = relations.oneToMany[i];
                if (data.hasOwnProperty(relation)) {
                    if (data[relation]){
                        data[relation + 'Id'] = data[relation].id;
                    }else{
                        data[relation + 'Id'] = null;
                    }
                }
                delete data[relation];
            }
        }

        return data;
    }
}