<template>
    <div v-if="value">
        <div class="grey lighten-3 px-4 py-4 text-caption rounded-lg mb-6 w-full">
                    <div class="d-flex justify-content-between">
                        <span class="text-subtitle-1">Info</span>
                        <v-btn v-if="!infoAdvanced" @click="infoAdvanced = true" small icon><v-icon small
                                left>mdi-pencil-outline</v-icon></v-btn>
                    </div>
                    <template v-if="infoAdvanced">
                        <v-card outlined class="mt-2">
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <eod-object-autocomplete endpoint="measurementTypes" return-object outlined dense clearable
                    :label="'Meetwaarde' + (value.content.type == 'thresholds' ? '*' : '')"
                    :value="value.measurementTypes ? value.measurementTypes[0] : null"
                    :fields="['id', 'name', 'unit{id name fields{name description} list{fields{name}}}', 'valueConfigList{id name description itemType fields{name description isActive}, listItems{id name description low high threshold stepSize color fields{name description isActive}}}', 'valueConfigListItems{id name description low high threshold stepSize color fields{name description isActive}}']"
                    @change="updateMeasurement"
                    :rules="value.content.type == 'thresholds' ? [val => rules.required(val, 'Meetwaarde')] : []"></eod-object-autocomplete>
                                    </v-col>
                                    <v-col>
                                        <eod-object-autocomplete class="mb-4" endpoint="jobTypes" return-object outlined dense label="jobType"
                    :value="value.jobTypes ? value.jobTypes[0] : null" @change="val => updateRelation('jobTypes', val)"
                    clearable
                    :fields="['id', 'name', 'defaultProject{id name company{id name}}', 'defaultService{id name}']"></eod-object-autocomplete>
                    <div class="mb-4">
                        <eod-objects-treeview-input clearable :selectable="['PROJECT']" :classes="['COMPANY', 'PROJECT']" @input="val => updateRelation('projects', val?{id:val}:null)" :value="(value.projects && value.projects[0])? value.projects[0].id : null" label="Project"></eod-objects-treeview-input>
                    </div>
                    <div class="mb-4">
                        <eod-objects-treeview-input clearable :selectable="['COMPANY']" :classes="['COMPANY']" @input="val => updateRelation('companies', val?{id:val}:null)" :value="(value.companies && value.companies[0])? value.companies[0].id : null" label="Bedrijf"></eod-objects-treeview-input>
                    </div>
                                    </v-col>
                                    <v-col>
                                        <div class="mb-4">
                                        <eod-objects-treeview-input clearable :selectable="['DEVICE']" @input="val => updateRelation('products', val?{id:val}:null)" :value="(value.products && value.products[0])? value.products[0].id : null" label="Toestel"></eod-objects-treeview-input>
                                    </div>
                                    <eod-object-autocomplete :key="updateServices" :suggestions="services" class="mb-4" endpoint="services" return-object outlined dense clearable label="Dienst"
                    :value="value.services ? value.services[0] : null"
                    @change="val => updateRelation('services', val)"></eod-object-autocomplete>
                                    <eod-user-selector class="mb-4" return-object outlined dense clearable label="Eigenaar"
                    :value="value.users ? value.users[0] : null"
                    @change="val => updateRelation('users', val)"></eod-user-selector>
                    
                                    </v-col>
                                </v-row>
                                
                                
                            </v-card-text>
                        </v-card>
                    </template>
                    <template v-else>
                        <v-row>
                            <v-col>
                                <div v-if="value.jobTypes && value.jobTypes[0]">Jobtype: <strong>{{ value.jobTypes[0].name
                        }}</strong></div>
                        <div v-if="value.projects && value.projects[0]">Project: <strong>{{ value.projects[0].name
                        }}</strong></div>
                        <div v-if="value.companies && value.companies[0]">Bedrijf: <strong>{{ value.companies[0].name
                        }}</strong></div>
                            </v-col>
                            <v-col>
                                <div v-if="value.products && value.products[0]">Toestel: <strong>{{ value.products[0].name
                        }}</strong></div>
                        <div v-if="value.users && value.users[0]">Eigenaar: <strong>{{
                            (new User(value.users[0])).getFullName()
                        }}</strong></div>
                        <div v-if="value.measurementTypes && value.measurementTypes[0]">Type meetwaarde: <strong>{{
                            value.measurementTypes[0].name
                        }}</strong></div>
                            </v-col>
                        </v-row>
                        
                        
                    </template>
                </div>
                <v-row>
                    <v-col>
                        <eod-autocomplete :value="value.content.type" @change="val => updateContent('type', val)" label="Type" :items="alarmTypes"></eod-autocomplete>
                    </v-col>
                    <v-col>
                        <div class="d-flex">
            <eod-text-field type="number" step="1" label="Interval"
                :value="value.content.interval / value.content.interval_divider" @input="val => updateContent('interval', val)"
                clearable></eod-text-field>
                <eod-autocomplete :value="value.content.interval_divider" @change="val => updateContent('interval_divider', val)" :items="[{value:1000, text: 'Seconden'},{value:60000, text: 'Minuten'}]"></eod-autocomplete>
            </div>
                    </v-col>
                </v-row>
                
                <eod-alarm-detail-thresholds v-if="value.content.type == 'thresholds'" :value="value"
            @input="val => $emit('input', val)"></eod-alarm-detail-thresholds>

        <eod-alarm-detail-listitems v-if="value.content.type == 'listitems'" :value="value"
            @input="val => $emit('input', val)" :valueItems="valueItems"></eod-alarm-detail-listitems>

        <eod-alarm-detail-deadman v-if="value.content.type == 'deadman'" :value="value"
            @input="val => $emit('input', val)"></eod-alarm-detail-deadman>
    </div>
</template>
<script>
import eodAlarmDetailThresholds from './eod-alarm-detail-thresholds.vue';
import eodAlarmDetailDeadman from './eod-alarm-detail-deadman.vue';
import eodAlarmDetailListitems from './eod-alarm-detail-listitems.vue';
import eodObjectAutocomplete from './eod-object-autocomplete.vue';
import eodUserSelector from './eod-user-selector.vue';
import eodObjectsTreeviewInput from './eod-objects-treeview-input.vue';
import AlarmRule from './../models/alarmRule';
import User from './../models/user';

export default {
    name: 'eod-alarm-detail',
    components: {
        eodAlarmDetailThresholds,
        eodAlarmDetailDeadman,
        eodAlarmDetailListitems,
        eodObjectAutocomplete,
        eodUserSelector,
        eodObjectsTreeviewInput
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        defaultFilters: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            alarmTypes: AlarmRule.types,
            User: User,
            rules: {
                required: (value, fieldName) => !!value || (fieldName ? fieldName : 'Veld') + ' is verplicht.',
            },
            valueItems: [],
            services: [],
            updateServices: 3434379,
            infoAdvanced: false
        }
    },
    async created() {
        let val = this.value;
        if (!val) {
            val = {
                name: '',
                type: 'ALARM_RULE',
                object: 'ORGANIZATION',
                access: 'PUBLIC',
                content: {
                    type: "thresholds",
                    interval: 60000,
                    interval_divider: 60000
                },
                measurementTypes: [],
                products: [],
                jobTypes: [],
                companies: [],
                projects: [],
                services: [],
                users: [],
                isActive: true,
            };

            this.infoAdvanced = true;

            this.$emit('input', val);
        }

        if(!val.content.interval_divider){
            val.content.interval_divider = 1000;
            this.$emit('input', val);
        }

        if (this.defaultFilters && this.defaultFilters.whereInRelated) {
            const relationMapping = {
                measurementTypeId: {
                    endpoint: 'measurementType',
                    field: 'measurementTypes',
                    fields: ['id', 'name', 'unit{id name fields{name description} list{fields{name}}}', 'valueConfigList{id name description itemType fields{name description isActive}, listItems{id name description low high threshold stepSize color fields{name description isActive}}}', 'valueConfigListItems{id name description low high threshold stepSize color fields{name description isActive}}']
                },
                productId: {
                    endpoint: 'product',
                    field: 'products',
                    fields: ['id','name']
                },
                jobTypeId: {
                    endpoint: 'jobType',
                    field: 'jobTypes',
                    fields: ['id', 'name', 'defaultProject{id name company{id name}}', 'defaultService{id name}']
                },
                companyId: {
                    endpoint: 'company',
                    field: 'companies',
                    fields: ['id','name','services{id}']
                },
                projectId: {
                    endpoint: 'project',
                    field: 'projects',
                    fields: ['id','name']
                },
                serviceId: {
                    endpoint: 'service',
                    field: 'services',
                    fields: ['id','name']
                },
                userId: {
                    endpoint: 'user',
                    field: 'users',
                    fields: ['id','firstName', 'lastName', 'username']
                },
            }
            for (let i = 0; i < this.defaultFilters.whereInRelated.length; i++) {
                const relationFilter = this.defaultFilters.whereInRelated[i];
                const config = relationMapping[relationFilter.column];
                const item = await this.$eod.getById(config.endpoint, relationFilter.array[0], config.fields)
                    .then(response => {
                        if(response.data.data[config.endpoint]){
                            return response.data.data[config.endpoint];
                        }
                    });

                if(item){
                    val[config.field] = [item];

                    if(relationFilter.column == 'measurementTypeId'){
                        this.updateMeasurement(item);
                    }else{
                        this.updateRelation(config.field, item)
                    }
                }
                
            }
        }
    },
    methods: {
        updateContent(property, value) {
            const val = this.value;
            if (val) {
                if(property == 'interval'){
                    val.content.interval = value * val.content.interval_divider;
                } else {
                    val.content[property] = value;
                }
                this.$emit('input', val);
            }
        },
        updateRelation(relationName, object) {
            if(relationName == 'companies'){
                this.services = [];
                if(object.services){
                    for (let i = 0; i < object.services.length; i++) {
                        const service = object.services[i];
                        this.services.push(service.id);
                    }
                }
                this.updateServices++;
            }

            const val = this.value;
            if (val && object) {
                val[relationName] = [object];
            } else if (val){
                val[relationName] = null;
            }

            this.$emit('input', val);
        },
        updateMeasurement(val) {
            this.updateRelation('measurementTypes', val);

            const items = this.$eod.getMeasurementTypeItems(val);
            this.valueItems = Object.values(items);

            if (this.valueItems && this.valueItems.length > 0) {
                this.updateContent('type', 'listitems');
            }
        }
    }
}
</script>