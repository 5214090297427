//import axios from "axios";
import helper from './../classes/helper';
import eod from './../classes/eod';

export default class User {
    static searchConfig = [{
        'icon': 'mdi-format-text',
        'label': 'Naam/E-mail',
        'type': 'search',
    },
    {
        'icon': 'mdi-format-text',
        'label': 'Rol',
        'type': 'roles',
        settings: {
            roles: require('@/eodvuecomponents/src/classes/settings.js').roles
        },
        'filter': 'roleNames'
    }
    ];
    static exportFilters = {
        isActive: false,
        enabled: true,
        roleNames: ['member', 'admin']
    }
    static exportFields = ['id', 'name', 'isSuper', 'firstName', 'lastName', 'username', 'email', 'assignedRealmRoles{id name}', 'attributes{phone_number, jobtitle nickname birthdate gender locality locale iban bic postal_code street_address region country notes extra_email extra_phone description}'];
    static moduleName = 'users';

    credentials = {
        temporary: false,
        type:'password',
        value: null
    }

    constructor(item) {
        if (item) {
            let settings = require('./../classes/settings');
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    if (key == 'attributes') {
                        if(!this.attributes){
                            this.attributes = {};
                        }
                        
                        for (const prop in item[key]) {
                            if (item[key].hasOwnProperty(prop)) {
                                this[key][prop] = item[key][prop];
                                if (item[key][prop] == null) {
                                    delete this[key][prop];
                                }
                            }
                        }
                    } else if (key == 'assignedRealmRoles' || key == 'roles') {
                        let roles = [];
                        if (item[key]) {
                            let count = item[key].length;
                            for (let i = 0; i < count; i++) {
                                const role = item[key][i];
                                if (!settings.roles.exception.includes(role.name)) {
                                    roles.push(role);
                                }
                            }
                        }

                        this[key] = roles;
                    } else {
                        this[key] = item[key];
                    }
                }
            }
        }
    }
    static fromJWT(token, organizationId) {
        /*axios.defaults.headers.common = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }*/

        const data = this.parseJwt(token);

        let realmRoles = [];
        for (let i = 0; i < data.realm_access.roles.length; i++) {
            const roleName = data.realm_access.roles[i];
            realmRoles.push({
                name: roleName
            });
        }

        return new this({
            id: data.sub,
            firstName: data.given_name,
            lastName: data.family_name,
            email: data.email,
            username: data.preferred_username,
            assignedRealmRoles: realmRoles,
            organization: {
                id: organizationId
            }
        });
    }
    static parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    PERMISSIONS = {};
    can(action) {
        if(this.PERMISSIONS[action]){
            return this.PERMISSIONS[action];
        }

        const myRole = this.getRole().name;
        if(myRole == 'root'){
            this.PERMISSIONS[action] = true;
            return true;
        }
        
        if (this.organization.configuration && this.organization.configuration.settings) {
            for (let i = 0; i < this.organization.configuration.settings.length; i++) {
                const setting = this.organization.configuration.settings[i];
                if (setting.key == 'permission.' + action) {
                    const permitted = JSON.parse(setting.value);
                    for (let j = 0; j < permitted.length; j++) {
                        const permittedRole = permitted[j];
                        if (permittedRole == myRole) {
                            this.PERMISSIONS[action] = true;
                            return true;
                        }
                    }
                }
            }
        }

        this.PERMISSIONS[action] = false;
        return false;
    }
    MY_GROUP = null
    getRoleGroup() {
        if(this.MY_GROUP){
            return this.MY_GROUP;
        }

        let settings = require('./../classes/settings');
        for (const group in settings.roles) {
            if (Object.hasOwnProperty.call(settings.roles, group)) {
                const groups = settings.roles[group];
                if (groups.includes(this.getRole().name)) {
                    this.MY_GROUP = group;
                    return this.MY_GROUP;
                }
            }
        }

        return null;
    }
    getGroupsString() {
        let str_groups = '';
        if (this.groups) {
            for (let i = 0; i < this.groups.length; i++) {
                const group = this.groups[i];
                str_groups += group.name;
                if (i < this.groups.length - 1) {
                    str_groups += ', ';
                }
            }
        }

        return str_groups;
    }
    get organizationId() {
        return this.organization.id;
    }
    MY_ROLE = null
    getRole() {
        if(this.MY_ROLE){
            return this.MY_ROLE;
        }

        let settings = require('./../classes/settings');
        let roles = this.assignedRealmRoles;

        for (let i = 0; i < roles.length; i++) {
            for (const roleGroup in settings.roles) {
                const checkRoles = settings.roles[roleGroup];
                if (checkRoles.includes(roles[i].name) && roleGroup != 'exception') {
                    this.MY_ROLE = roles[i];
                    return this.MY_ROLE;
                }
            }
        }

        return null;
    }
    getInitials() {
        let name = this.username.charAt(0);

        if (this.firstName && this.lastName) {
            name = this.firstName.charAt(0) + this.lastName.charAt(0);
        } else if (this.firstName) {
            name = this.firstName.charAt(0);
        } else if (this.lastName) {
            name = this.lastName.charAt(0);
        }

        return name;
    }
    getFullName() {

        let name = this.username;

        if (this.firstName && this.lastName) {
            name = this.firstName + ' ' + this.lastName;
        } else if (this.firstName) {
            name = this.firstName;
        } else if (this.lastName) {
            name = this.lastName;
        }

        return name;
    }
    getRelatedItems(){
        const items = [];
        
        const relations = ['projects','companies'];
        for (let relationIndex = 0; relationIndex < relations.length; relationIndex++) {
          const relationName = relations[relationIndex];
          if(this[relationName]){
            for (let i = 0; i < this[relationName].length; i++) {
              const item = this[relationName][i];
              items.push({
                id: item.id,
                module: relationName,
                parentId: item.parentId?item.parentId:(item.company?item.company.id:null),
                parentIds: item.parentIds?item.parentIds:(item.company?[item.company.id]:[])
              });
            }
          }
        }
  
        return items;
    }
    setAccess(selection){
        this.projects = [];
        this.companies = [];
  
        for (let i = 0; i < selection.length; i++) {
          const selectedItem = selection[i];
          if(selectedItem.module){
            this[selectedItem.module].push(selectedItem);
          }
        }
    }
    async getSaveData() {

        let data = Object.assign({}, this);

        delete data.PERMISSIONS;
        delete data.MY_GROUP;
        delete data.MY_ROLE;

        if(data.organization){
            data.organizationId = data.organization.id;
            delete data.organization;
        }

        if (data.assignedRealmRoles && data.assignedRealmRoles[0]) {
            let settings = require('./../classes/settings');
            if (data.assignedRealmRoles[0].name == 'connector' && data.name) {
                if (data.name) {
                    data.username = data.name;
                    data.email = helper.slugify(data.name) + '@eyeondata.be';
                }
            }

            for (let i = 0; i < settings.roles.exception.length; i++) {
                data.assignedRealmRoles.push({ name: settings.roles.exception[i] });
            }
        }

        if (data.credentials) {
            if (data.credentials.value == '' || data.credentials.value == null) {
                delete data.credentials;
            }
        }

        if (!data.id && !data.credentials) {
            const defaultpass = eod.getOrganizationSetting('organization.contacts.defaultPassword');
            if (defaultpass && defaultpass.value && defaultpass.value != '') {
                data.credentials = {
                    temporary: false,
                    type: 'password',
                    value: defaultpass.value
                }
            }
        }

        const oneToManyRelations = ['depot', 'vehicle'];
        for (let i = 0; i < oneToManyRelations.length; i++) {
            const relationName = oneToManyRelations[i];
            if (data.hasOwnProperty(relationName)) {
                if (data[relationName]) {
                    data[relationName+'Id'] = data[relationName].id;
                } else {
                    data[relationName+'Id'] = null;
                }
                delete data[relationName];
            }
        }

        const manyToManyRelations = ['skills', 'companies', 'projects', 'alarmTemplates', 'acls'];
        for (let i = 0; i < manyToManyRelations.length; i++) {
            const relationName = manyToManyRelations[i];
            if (data.hasOwnProperty(relationName)) {
                for (let j = 0; j < data[relationName].length; j++) {
                    data[relationName][j] = {
                        id: data[relationName][j].id,
                        organizationId: data.organizationId
                    }
                }
            }
        }

        if (data.hasOwnProperty('templates') && data.templates) {
            let templates = [];
            for (let i = 0; i < data.templates.length; i++) {
                const template = data.templates[i];
                const jobTypes = template.jobTypes;
                delete template.jobTypes;
                const templateId = await eod.save('Template', template)
                    .then(response => {
                        if (response.data.data.createTemplate) {
                            if (template.type == 'JOB_TYPE_CONFIG') {
                                return eod.save('Acl', {
                                    userId: template.content.ownerId,
                                    jobTypeId: template.content.jobTypeId,
                                    acc:3,
                                    topic: '+/' + template.content.ownerId + '/#'
                                }).then(() => {
                                    return response.data.data.createTemplate.id;
                                });
                            }
                            return response.data.data.createTemplate.id;
                        } else if (response.data.data.updateTemplate) {
                            return response.data.data.updateTemplate.id;
                        }

                        return null;
                    });

                if (templateId) {
                    templates.push({ id: templateId, default: false });

                    if (template.type == 'JOB_TYPE_CONFIG') {
                        for (let j = 0; j < jobTypes.length; j++) {
                            const jobType = jobTypes[j];
                            await eod.getById('jobType', jobType.id, ['id', 'name', 'templates{id default}']).then(response => {
                                const jobType = response.data.data.jobType;
                                const my_templates = [];
                                if (jobType.templates) {
                                    for (let k = 0; k < jobType.templates.length; k++) {
                                        const jobTypeTemplate = jobType.templates[k];
                                        if (jobTypeTemplate.id != templateId) {
                                            my_templates.push({ id: jobTypeTemplate.id, default: jobTypeTemplate.default ? true : false });
                                        }
                                    }
                                }

                                my_templates.push({ id: templateId, default: false });

                                const jobTypeData = {
                                    id: jobType.id,
                                    name: jobType.name,
                                    templates: my_templates,
                                };

                                return eod.save('JobType', jobTypeData);
                            });
                        }
                    }
                }
            }
            data.templates = templates;
        }

        return data;
    }
};