import moment from 'moment-timezone';
import User from './user';
import Task from './task';
import eod from './../classes/eod';
import getEnv from './../classes/utils';
import _ from 'lodash';

export default class Project {
    static fields = {
        name: String,
        tasks_count: {
            type: Number,
            query: ['tasks.id']
        },
        tasks_table: {
            type: String,
            query: ['tasks.id', 'tasks.name']
        },
        responses_count: {
            type: Number,
            query: ['tasks.responses.id', 'tasks.responses.statusCode']
        },
        //tasks: Task,
        users: User,
    }
    static moduleName = 'projects';
    static exportFields = ['id', 'name', 'description', 'isActive', 'locations{name description streetAddress postalCode locality region country notes}', 'company{name}', 'users{firstName, lastName, username, email}', 'groups{id name}'];
    static exportFilters = {
        isActive: false,
    }
    static searchConfig = [
        {
            'icon': 'mdi-format-text',
            'label': 'Naam',
            'type': 'property',
            'filter': 'name'
        },
        {
            'icon': 'mdi-format-text',
            'label': 'Omschrijving',
            'type': 'property',
            'filter': 'description'
        },
        {
            'icon': 'mdi-office-building-outline',
            'label': 'Bedrijf',
            'type': 'module',
            'settings': {
                'module': 'companies',
                'fields': ['name'],
                'title': '{name}',
            },

            'filter': 'companyId'
        },
        {
            'icon': 'mdi-alarm',
            'label': 'Wijzigingsdatum',
            'type': 'date-between',
            'filter': 'updatedAt'
        },
    ];
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];

                    if (key == 'users' && this[key]) {
                        for (let i = 0; i < this[key].length; i++) {
                            this[key][i] = new User(this[key][i]);
                        }
                    }
                    else if (key == 'tasks' && this[key]) {
                        for (let i = 0; i < this[key].length; i++) {
                            this[key][i] = new Task(this[key][i]);
                        }
                    }
                }
            }
        }
    }
    getRealm() {
        if (getEnv('VUE_APP_KEYCLOAK_REALM')) {
            return getEnv('VUE_APP_KEYCLOAK_REALM');
        } else {
            var hostname = window.location.hostname;
            var hostparts = hostname.split('.');
            return hostparts[0];
        }
    }
    tasks_table(user) {
        let table = '<table border="0" width="100%">';
        let mobile_url = getEnv('VUE_APP_MOBILE_URL');
        let lang = 'nl';
        if (user.attributes && user.attributes.locale && user.attributes.locale[0]) {
            lang = user.attributes.locale[0];
        }

        if (this.tasks) {
            for (let i = 0; i < this.tasks.length; i++) {
                const task = this.tasks[i];
                table += "<tr><td><a href=\"" + ('https://' + this.getRealm() + '.' + mobile_url + '/form/' + task.id + '/response?lang=' + lang) + "\">" + task.name + "</a></td></tr>"
            }
        }
        table += '</table>';
        return table;
    }
    get tasks_count() {
        if (this.tasks) {
            return this.tasks.length;
        }

        return 0;
    }
    get responses_count() {

        let total = 0;
        if (this.tasks) {
            for (let i = 0; i < this.tasks.length; i++) {
                const task = this.tasks[i];
                for (let j = 0; j < task.responses.length; j++) {
                    const response = task.responses[j];
                    if (response.statusCode != 'in_progress') {
                        total++;
                    }
                }

            }
        }

        return total;
    }
    get startDateFormatted() {
        if (this.startDate) {
            return moment(this.startDate).format('YYYY-MM-DD');
        }
        return null;
    }
    set startDateFormatted(value) {
        this.startDate = moment(value, 'YYYY-MM-DD').toISOString();
    }
    get orderedDateFormatted() {
        if (this.orderedDate) {
            return moment(this.orderedDate).format('YYYY-MM-DD');
        }
        return null;
    }
    set orderedDateFormatted(value) {
        this.orderedDate = moment(value, 'YYYY-MM-DD').toISOString();
    }
    get requiredDateFormatted() {
        if (this.requiredDate) {
            return moment(this.requiredDate).format('YYYY-MM-DD');
        }
        return null;
    }
    set requiredDateFormatted(value) {
        this.requiredDate = moment(value, 'YYYY-MM-DD').toISOString();
    }
    get deliveredDateFormatted() {
        if (this.deliveredDate) {
            return moment(this.deliveredDate).format('YYYY-MM-DD');
        }
        return null;
    }
    set deliveredDateFormatted(value) {
        this.deliveredDate = moment(value, 'YYYY-MM-DD').toISOString();
    }
    get contacts() {
        let settings = require('./../classes/settings');
        let contacts = [];
        if (this.users) {
            for (let i = 0; i < this.users.length; i++) {
                if (this.users[i].getRole() && settings.roles.contacts.includes(this.users[i].getRole().name)) {
                    contacts.push(this.users[i]);
                }
            }
        }

        return contacts;
    }
    get collaborators() {
        let settings = require('./../classes/settings');
        let collaborators = [];
        if (this.users) {
            for (let i = 0; i < this.users.length; i++) {

                if (this.users[i].getRole() && settings.roles.collaborators.includes(this.users[i].getRole().name)) {
                    collaborators.push(this.users[i]);
                }
            }
        }

        return collaborators;
    }
    async runningTicketsCount() {
        var count = 0;
        var tickets = await this.getTickets();
        for (let i = 0; i < tickets.length; i++) {
            const ticket = tickets[i];
            if (ticket.statusCode != 'done' && ticket.statusCode != null) {
                count++;
            }
        }

        return count;
    }
    getTickets() {

        const config = eod.getOrganizationSetting('tasks.ticket.taskTypeId');
        return new Promise((resolve, reject) => {

            if (!config) {
                reject('No configuration found');
            }

            if (!config.value) {
                reject('No configuration found');
            }

            if (!this.tickets) {
                eod.get('tasks', ['id name description statusCode parent{id name description} company{id name} project{id name description} taskType{id name} service{id name}'], {
                    where: [{
                        column: "taskTypeId",
                        operator: "=",
                        value: config.value
                    }, {
                        column: "projectId",
                        operator: "=",
                        value: this.id,
                        chain: "and"
                    }, {
                        column: "category",
                        operator: "=",
                        value: 'JOB',
                        chain: "and"
                    },
                    {
                        column: "stepType",
                        operator: "=",
                        value: 'FORM',
                        chain: "and"
                    }]
                }).then(response => {
                    if (response.data.data.tasks.edges) {
                        this.tickets = response.data.data.tasks.edges;
                        resolve(this.tickets);
                    }
                }).catch(reject);
            } else {
                resolve(this.tickets);
            }
        })
    }
    loadLocations() {
        return eod.getById('project', this.id, ['locations{name description streetAddress postalCode locality region country notes}'])
            .then(response => {
                if (response.data.data.project) {
                    this.locations = response.data.data.project.locations;
                }
            });
    }
    loadUsers() {
        return eod.getById('project', this.id, ['users{id, firstName, lastName, username, email, assignedRealmRoles{id name}, attributes{phone_number, jobtitle nickname birthdate gender locality locale iban bic postal_code street_address region country notes extra_email extra_phone}}'])
            .then(response => {
                let users = [];
                if (response.data.data.project) {
                    for (let i = 0; i < response.data.data.project.users.length; i++) {
                        const usr = response.data.data.project.users[i];

                        users.push(new User(usr));
                    }
                }

                this.users = users;
            });
    }
    getSaveData() {
        let data = _.cloneDeep(this);

        if(this.company){
            data.companyId = this.company.id;
            delete data.company;
        }

        // storageObjects is contolled seperatly by eod-storage-library
        delete data.storageObjects;

        const manyToManyRelations = ['users', 'groups', 'products', 'tags'];
        for (let i = 0; i < manyToManyRelations.length; i++) {
            const relationName = manyToManyRelations[i];
            if (data.hasOwnProperty(relationName)) {
                for (let j = 0; j < data[relationName].length; j++) {
                    data[relationName][j] = {
                        id: data[relationName][j].id
                    }
                }
            }
        }

        if(data.locations){
            for (let i = 0; i < data.locations.length; i++) {
                if(data.locations[i].lat){
                    data.locations[i].lat = parseFloat(data.locations[i].lat);
                }
                if(data.locations[i].lon){
                    data.locations[i].lon = parseFloat(data.locations[i].lon);
                }
            }
        }

        if (data.templates) {
            for (let i = 0; i < data.templates.length; i++) {
                const template = data.templates[i];
                data.templates[i] = {
                    id: template.id,
                    default: template.default ? true : false
                }
            }
        }

        delete data.createdAt;
        delete data.updatedAt;

        return data;
    }
}