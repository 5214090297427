import AlarmRule from './../models/alarmRule';
import Event from './../models/event';

export default {
    props:{
        value: Object | Array,
        from: Object,
        until: Object,
    },
    data: () => ({
        graphOptions: {},
        series: null,
    }),
    methods: {
        async loadAlarmTemplates(series){
            // Only get alarms when single serie -> otherwise unable to define multiple alarm values
            if(series.length == 1){
                const serie = series[0];
                const productId = serie.data[0]?serie.data[0].value[2].product_id:null;

                const measurementTypeId = serie.data[0]?serie.data[0].value[2].measurement_type_id:null;
                
                const projectFilter = this.$helper.getFilterByKey(this.filters, 'project_id');
                const projectId = projectFilter?projectFilter.value:null;

                if(measurementTypeId && productId && projectId){
                    const alarmTemplate = await this.$eod.get('templates', ['id', 'content'], {
                        where:[{
                            column:'type',
                            operator:'=',
                            value:'ALARM_RULE'
                        },
                        {
                            column:'content:type',
                            operator:'=',
                            value:'thresholds'
                        },
                        {
                            column:'isActive',
                            operator:'=',
                            value:'1'
                        }],
                        whereInRelated:[
                            {
                                column: 'measurementTypeId',
                                relation: 'measurementTypes_templates',
                                array: [measurementTypeId]
                            },
                            {
                                column: 'productId',
                                relation: 'products_templates',
                                array: [productId]
                            },
                            {
                                column: 'projectId',
                                relation: 'projects_templates',
                                array: [projectId]
                            }
                        ]
                    }).then(response => {
                        if(response.data.data && response.data.data.templates.edges && response.data.data.templates.edges[0]){
                            return response.data.data.templates.edges[0];
                        }

                        return null;
                    });

                    if(alarmTemplate){

                        series[0].markArea = {
                            silent:true,
                            itemStyle:{
                                opacity: 0.1
                            },
                            data: []
                        }

                        const data = [];

                        for (let i = 0; i < AlarmRule.severities.length; i++) {
                            const severity = AlarmRule.severities[i];
                            let min = null;
                            if(alarmTemplate.content.severities[severity.value]){

                                switch(severity.value){
                                    case 'criticalLow':
                                        data.push([
                                            {y:'min', itemStyle:{color:severity.hex}},
                                            {yAxis: parseFloat(alarmTemplate.content.severities[severity.value])}
                                        ]);
                                        break;
                                    case 'warningLow':
                                    case 'infoLow':
                                        min = data[data.length-1]?data[data.length-1][1]:{y:'min'};
                                        min.itemStyle = {color:severity.hex};
                                        data.push([
                                            min,
                                            {yAxis: parseFloat(alarmTemplate.content.severities[severity.value])}
                                        ]);
                                        break;
                                    case 'ok':
                                        min = data[data.length-1]?data[data.length-1][1]:{y:'min'};
                                        min.itemStyle = {color:severity.hex};
                                        data.push([
                                            min,
                                            {y:'max', itemStyle:{color:severity.hex}}
                                        ]);
                                        break;
                                    case 'infoHigh':
                                    case 'warningHigh':
                                    case 'criticalHigh':
                                        if(data[data.length-1]){
                                            data[data.length-1][1] = {yAxis: parseFloat(alarmTemplate.content.severities[severity.value])};
                                        }
                                        data.push([
                                            {yAxis: parseFloat(alarmTemplate.content.severities[severity.value])},
                                            {y:'max', itemStyle:{color:severity.hex}}
                                        ]);
                                        break;
                                }

                                //prev = severity.value;
                            }
                        }

                        series[0].markArea.data = data;
                    }
                }

                this.renderGraph(series);
            }
        },
        getTimeInterval() {
            return { from: this.from, until: this.until };
        },
        renderGraph(series){
            this.graphOptions.series = series;
            if (this.$refs[this.id]) {                
                this.$refs[this.id].mergeOptions(this.graphOptions);
                this.$nextTick(() => {
                    this.$forceUpdate();
                    this.$refs[this.id].dispatchAction({
                        type: 'takeGlobalCursor',
                        key: 'dataZoomSelect',
                        dataZoomSelectActive: true
                    });
                });
            } else {
                console.log('graph not found');
            }
        },
        async loadEvents() {
            if(!this.series){
                return;
            }

            if(this.cell && this.cell.settings && this.cell.settings.hideEvents){
                return;
            }

            for (let i = 0; i < this.series.length; i++) {
                if(!this.series[i].data[0]){
                    continue;
                }

                const serieValue = this.series[i].data[0].value[2];

                if(!serieValue.measurement_type_id){
                    continue;
                }

                let interval = this.getTimeInterval();

                const whereFilters = [
                    { column: 'measurementTypeId', operator: '=', value: serieValue.measurement_type_id },
                    { column: 'time', operator: '>=', value: interval.from },
                    { column: 'time', operator: '<=', value: interval.until }
                ];

                if(this.$route && this.$route.query && this.$route.query.project_id){
                    whereFilters.push({ column: 'projectId', operator: '=', value: this.$route.query.project_id });
                }

                const isNullFilters = [];

                if (serieValue.owner_id) {
                    whereFilters.push({ column: 'ownerId', operator: '=', value: serieValue.owner_id });
                } else {
                    isNullFilters.push({ column: 'ownerId' });
                }

                if (serieValue.product_id) {
                    whereFilters.push({ column: 'productId', operator: '=', value: serieValue.product_id });
                } else {
                    isNullFilters.push({ column: 'productId' });
                }

                const filter = {
                    where: whereFilters,
                }

                if (isNullFilters.length > 0) {
                    filter.whereNull = isNullFilters;
                }

                const allEvents = await this.$eod.get('resolveEvents', ['id', 'message', 'time', 'duration', 'value', 'severity', 'facility'], filter).then(response => {
                    return response.data.data.resolveEvents.edges;
                });

                const events = [];
                if(allEvents){
                    for (let k = 0; k < allEvents.length; k++) {
                        const event = allEvents[k];
                        this.series[i].data.push({
                            time_interval: event.time,
                            ...event
                        });
                        events.push({
                            itemStyle: {
                                color: Event.getSeverity(event.severity).color
                            },
                            /*itemStyle: {
                                color: '#000000'
                            },*/
                            name: 'Event',
                            value: [event.time, '', event, ''],
                            xAxis: event.time,
                            yAxis: event.value
                        });
                    }
                }

                this.series[i].markPoint = {
                    symbolSize: 18,
                    symbol: "circle",
                    data: events
                }

                //this.series[i].events = events;
            }
            this.renderGraph(this.series);
        },
    },
}