<template>
    <v-dialog
      :value="value"
      @input="(val) => {$emit('input', val)}"
      max-width="800"
    >
      <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step
        :complete="step > 1"
        step="1"
      >
        Gegevens
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="step > 2"
        step="2"
      >
        Geadresseerden
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3" :complete="step > 3">
        Bericht
      </v-stepper-step>
      <v-stepper-step step="4">
        Verzonden
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
          <div class="px-2 py-2 mb-4">
                <v-select @change="getModuleItems" return-object v-model="selectedObject" :items="moduleObjects" item-value="endpoint" item-text="name" outlined label="Verstuur gegevens over"></v-select>

                <v-card outlined>
                    <v-data-table
                        v-model="selectedItems"
                        :headers="itemHeaders"
                        :items="moduleItems"
                        item-key="id"
                        show-select
                        return-object
                    >
                    </v-data-table>
                </v-card>
          </div>
          <v-divider></v-divider>
          <div class="px-2 py-2 d-flex">
              <v-btn @click="$emit('input', false)">Annuleren</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="showContacts" :loading="usersLoading">
                    Volgende
                </v-btn>
          </div>
      </v-stepper-content>

      <v-stepper-content step="2">
        <div class="px-2 py-2 mb-4">
            <v-card outlined>
                    <v-data-table
                        v-model="selectedUsers"
                        :headers="userHeaders"
                        :items="users"
                        item-key="id"
                        show-select
                        return-object
                    >
                    </v-data-table>
                </v-card>
        </div>
        <div class="px-2 py-2 d-flex">
              <v-btn @click="step = 1">Vorige</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="showContentEditor">
                    Volgende
                </v-btn>
          </div>
      </v-stepper-content>

      <v-stepper-content step="3">
        <div class="px-2 py-2 mb-4">
            <v-tabs v-model="tab" background-color="transparent" color="primary" grow>
                <v-tab v-for="language in languages" :key="language.value">
                    {{ language.text }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item v-for="language in languages" :key="language.value">
                    <v-card flat>
                        <v-card-text>
                            <v-text-field dense outlined label="Onderwerp" v-model="subject[language.value]"></v-text-field>
                            <v-text-field dense outlined label="Preheader" v-model="preHeader[language.value]"></v-text-field>
                            <tiptap-vuetify
                                v-model="content[language.value]"
                                :extensions="extensions"
                                placeholder="Typ hier uw tekst..."
                                class="mb-2"
                            />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>

            
    <v-card outlined>
        <v-card-subtitle>Beschikbare tokens</v-card-subtitle>
    <v-treeview :items="replaceTokens" dense>
        <template slot="label" slot-scope="{ item }">
            {{ item.name }} | <strong v-if="item.tag" v-html="'{{'+item.tag+'}}'"></strong>
        </template>
    </v-treeview>
    </v-card>
        </div>
        <div class="px-2 py-2 d-flex">
              <v-btn @click="step = 2">Vorige</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="send">
                    Verzenden
                </v-btn>
          </div>
      </v-stepper-content>
      <v-stepper-content step="4">
          <div class="px-2 py-2 mb-4">
              <h2>Verzonden</h2>
              <p>De e-mails zijn succesvol verzonden.</p>
          </div>
          <div class="px-2 py-2 d-flex">
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="$emit('input', false)">
                    Sluiten
                </v-btn>
          </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
    </v-dialog>
</template>
<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, History, HardBreak } from 'tiptap-vuetify'

    export default {
        props:{
            value: Boolean
        },
        components: { TiptapVuetify },
        data: () => ({
            // General
            step: 1,
            settings: require('./../plugins/settings'),

            // Data
            selectedObject: null,
            selectedItems: [],
            itemHeaders: [
                { text: 'Naam', value: 'name' },
            ],
            moduleItems: [],
            moduleObjects: [],
            
            // Contacts
            usersLoading: false,
            users: [],
            selectedUsers: [],
            userHeaders: [
                { text: 'Voornaam', value: 'firstName' },
                { text: 'Naam', value: 'lastName' },
                { text: 'E-mailadres', value: 'email' },
            ],

            // Content
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                    levels: [1, 2, 3]
                    }
                }],
                Bold,
                Paragraph,
                HardBreak
            ],
            // starting editor's content
            languages: [],
            tab: '',
            subject: {},
            preHeader: {},
            content: {},
            replaceTokens:[],

            succesfully: [],
        }),
        created(){
            this.languages = this.$eod.getLanguages();
        },
        mounted(){
            this.$nextTick(() => {
                for (const modulename in this.settings.modules) {
                    if (Object.hasOwnProperty.call(this.settings.modules, modulename)) {
                        const my_module = this.settings.modules[modulename];
                        if (my_module.model && my_module.model.fields) {
                            if (my_module.model.fields.users) {
                                this.moduleObjects.push({
                                    endpoint: modulename,
                                    icon: my_module.icon,
                                    name: this.$t(my_module.item.toUpperCase()),
                                });
                            }
                        }
                    }
                }
            });
        },
        methods:{
            getDefaultLanguage(){
                for (let i = 0; i < this.languages.length; i++) {
                    const language = this.languages[i];
                    if (language.isDefault) {
                        return language;
                    }
                    
                }
            },
            isValidUser(userid){
                for (let i = 0; i < this.selectedUsers.length; i++) {
                    const user = this.selectedUsers[i];
                    if (user.id == userid) {
                        if (!this.succesfully.includes(user.email)) {
                            return user;
                        }
                    }
                }

                return null;
            },
            async send(){
                let to_send = [];
                let itemIds = [];
                for (let i = 0; i < this.selectedItems.length; i++) {
                    const item = this.selectedItems[i];
                    for (let j = 0; j < item.users.length; j++) {
                        const user = item.users[j];
                        let userObj = this.isValidUser(user.id);
                        if (userObj) {
                            to_send.push({
                                user: userObj,
                                itemId: item.id
                            });

                            if (!itemIds.includes(item.id)) {
                                itemIds.push(item.id);
                            }
                        }
                    }
                }

                let used_tokens = this.getUsedTokens();
                let query = this.getQueryByTokens(used_tokens);

                let endpoint = query.substring(0, query.indexOf('{'));
                let fields = query.substring(endpoint.length+1, query.length-1);

                await this.$eod.get(this.selectedObject.endpoint, ['id', fields], {
                    whereIn: {
                        column: 'id',
                        array: itemIds
                    }
                })
                .then(async response => {
                    let my_module = this.settings.modules[this.selectedObject.endpoint];
                    let items = response.data.data[this.selectedObject.endpoint].edges;

                    let itemsById = {};
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        itemsById[item.id] = new my_module.model(item);
                    }

                    let count = 0;
                    for (let i = 0; i < to_send.length; i++) {
                        const sendinfo = to_send[i];
                        await this.sleep(2000);
                        this.sendEmail(itemsById[sendinfo.itemId], sendinfo.user);

                        count++;
                        if (count == 50) {
                            await this.sleep(120000);
                            count = 0;
                        }
                    }

                    this.step = 4;
                });
            },
            sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            getUsedTokens(){
                let used_tokens = [];

                for (const lang in this.content) {
                    if (Object.hasOwnProperty.call(this.content, lang)) {
                        const content = this.content[lang];
                        var matches = content.match(/\{\{(.*?)\}\}/g);

                        if (matches) {
                            for (let i = 0; i < matches.length; i++) {
                                const token = matches[i];
                                if (!used_tokens.includes(token)) {
                                    used_tokens.push(token);
                                }
                            }
                        }
                    }
                }

                return used_tokens;
            },
            sendEmail(item, user){
                let data = this.getEmailContent(item, user);
                this.$eod.sendMessage(user, data).then(result => {
                    console.log(result);
                });
            },
            isFunction(functionToCheck) {
                return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
            },
            getEmailContent(item, user){

                let used_tokens = this.getUsedTokens();

                let fields = {};
                for (let i = 0; i < used_tokens.length; i++) {
                    const token = used_tokens[i];
                    const tag = used_tokens[i].replace('{{', '').replace('}}', '');
                    const parts = tag.split('.');
                    
                    let val = item;
                    for (let j = 1; j < parts.length; j++) {
                        const part = parts[j];
                        if (this.isFunction(val[part])) {
                            val = val[part](user);
                        }else{
                            val = val[part];
                        }
                        
                    }

                    fields[token] = val;
                }

                let locale = this.getDefaultLanguage().value;
                let content = this.content[locale];
                let preHeader = this.preHeader[locale];
                let subject = this.subject[locale];
                if (user.attributes && user.attributes.locale) {
                    locale = user.attributes.locale;
                }

                if (this.content[locale] && this.content[locale] != '') {
                    content = this.content[locale];
                }

                if (this.subject[locale] && this.subject[locale] != '') {
                    subject = this.subject[locale];
                }

                if (this.preHeader[locale] && this.preHeader[locale] != '') {
                    preHeader = this.preHeader[locale];
                }

                for (const token in fields) {
                    if (Object.hasOwnProperty.call(fields, token)) {
                        const value = fields[token];

                        let regex = token;
                        let re = new RegExp(regex, "g");

                        content = content.replace(re, value);
                    }
                }                

                return {
                    html: content,
                    preheader: preHeader,
                    subject: subject,
                };
            },
            getQueryByTokens(used_tokens){

                let tree = {};
                for (let i = 0; i < used_tokens.length; i++) {
                    const tag = used_tokens[i].replace('{{', '').replace('}}', '');

                    let field = this.getFieldByTag(tag);
                    if (field && field.query) {
                        let parent = tag.substring(0, tag.lastIndexOf('.')+1);
                        for (let j = 0; j < field.query.length; j++) {
                            const qry = parent+field.query[j];
                            let obj = {};
                            obj[qry] = null;
                            obj = this.deepen(obj);
                            tree = this.$helper.mergeObjects(tree, obj);
                        }
                    }else{
                        let obj = {};
                        obj[tag] = null;
                        obj = this.deepen(obj);
                        tree = this.$helper.mergeObjects(tree, obj);
                    }
                }
                
                let query = this.convertTreeToQuery(tree);

                return query;
            },
            getFieldByTag(tag){
                let my_module = this.settings.modules[this.selectedObject.endpoint];
                let parts = tag.split('.');

                let model = my_module.model;
                for (let i = 1; i < parts.length; i++) {
                    const part = parts[i];
                    
                    if (model.type) {
                        if (model.type.fields) {
                            model = model.type.fields[part];
                        }
                    }else if (model.fields) {
                        model = model.fields[part];
                    }
                }

                return model;
            },
            convertTreeToQuery(tree){
                let query = '';
                for (const fieldname in tree) {
                    if (Object.hasOwnProperty.call(tree, fieldname)) {
                        const obj = tree[fieldname];
                        query += fieldname;
                        if (obj) {
                            query += '{'+this.convertTreeToQuery(obj)+'}';
                        }
                        query += ',';
                    }
                }

                query = query.replace(/,+$/,'');

                return query;
            },
            deepen(obj) {
                const result = {};

                // For each object path (property key) in the object
                for (const objectPath in obj) {
                    // Split path into component parts
                    const parts = objectPath.split('.');

                    // Create sub-objects along path as needed
                    let target = result;
                    while (parts.length > 1) {
                    const part = parts.shift();
                    target = target[part] = target[part] || {};
                    }

                    // Set value at end of path
                    target[parts[0]] = obj[objectPath]
                }

                return result;
            },
            getChildTokens(ObjectClass, tag){
                let children = [];

                for (const fieldname in ObjectClass.fields) {
                    if (Object.hasOwnProperty.call(ObjectClass.fields, fieldname)) {
                        const fieldClass = ObjectClass.fields[fieldname];

                        let field = {
                            id: fieldname,
                            name: fieldname,
                            tag: tag+'.'+fieldname,
                        }

                        if (fieldClass.fields || (fieldClass.type && fieldClass.type.fields)) {
                            let field_children = [];
                            if (fieldClass.fields) {
                                field_children = this.getChildTokens(fieldClass, tag+'.'+fieldname);
                            }else if (fieldClass.type && fieldClass.type.fields){
                                field_children = this.getChildTokens(fieldClass.type, tag+'.'+fieldname);
                            }
                            
                            if (children.length > 0) {
                                field.children = field_children;
                            }
                        }

                        children.push(field);
                    }
                }

                if (children.length == 0) {
                    return null;
                }

                return children;
            },
            showContentEditor(){
                let my_module = this.settings.modules[this.selectedObject.endpoint];
                this.replaceTokens = [{
                    id: this.selectedObject.endpoint,
                    name: this.selectedObject.name,
                    children: this.getChildTokens(my_module.model, my_module.item)
                }];

                this.step = 3;
            },
            showContacts(){
                this.usersLoading = true;

                let userids = [];
                for (let i = 0; i < this.selectedItems.length; i++) {
                    const selectedItem = this.selectedItems[i];
                    for (let j = 0; j < selectedItem.users.length; j++) {
                        const user = selectedItem.users[j];
                        if (!userids.includes(user.id)) {
                            userids.push(user.id);
                        }
                    }
                }

                this.$eod.get('users', ['id', 'firstName', 'lastName', 'username', 'email', 'attributes{locale}'], {
                    whereIn: {
                        column: 'id',
                        array: userids
                    }
                }).then(result => {
                    this.users = result.data.data.users.edges;
                    this.selectedUsers = this.users;
                    this.step = 2;
                }).finally(() => {
                    this.usersLoading = false;
                });
            },
            getModuleItems(){
                this.$eod.get(this.selectedObject.endpoint, ['id', 'name', 'users{id}'])
                    .then(result => {
                        this.moduleItems = result.data.data[this.selectedObject.endpoint].edges;
                    });
            }
        }
    }
</script>